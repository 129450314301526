export const setValue = (inputId, value) => {
    const element = document.getElementById(inputId);
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype,'value').set;
    nativeInputValueSetter.call(element, value);
    const event = new Event('input', { bubbles: true });
    element.dispatchEvent(event);
}

const copyValue = (e, fromInput, toInput) => {
    if (Array.isArray(fromInput) && Array.isArray(toInput)) {
        fromInput.forEach((from, index) => {
            const fromElement = document.getElementById(from);
            const toElement = document.getElementById(toInput[index]);
            setValue(toElement.id, fromElement.value);
        });
    } else if (Array.isArray(fromInput) && !Array.isArray(toInput)) {
        fromInput.forEach((from, index) => {
            const fromElement = document.getElementById(from);
            const toElement = document.getElementById(toInput);
            setValue(toElement.id, fromElement.value);
        });
    } else if (!Array.isArray(fromInput) && Array.isArray(toInput)) {
        const fromElement = document.getElementById(fromInput);
        toInput.forEach((to, index) => {
            const toElement = document.getElementById(to);
            setValue(toElement.id, fromElement.value);
        });
    } else {
        const from = document.getElementById(fromInput);
        const to = document.getElementById(toInput);
        setValue(to.id, from.value);
    }
}

export default copyValue;