import Formgenerator from "../../components/Form";
import { useTranslation } from "react-i18next";

const module = "setting";

const Edit = ({id = 1}) => {
    const { t } = useTranslation();

    const fields = [
        {
            id: "tab-0",
            type: "tab",
            children: [
                {
                    label: t("Ügyféltörzs"),
                    children: [
                        {
                            id: "default_transfer_day",
                            type: "text",
                            label: t("Alapértelmezett átutalás"),
                            append: t("nap")
                        },  
                        {
                            id: "partner_same_email",
                            type: "checkbox",
                            label: t("Azonos ügyfél e-mail cím engedélyezése")
                        },  
                        {
                            id: "partner_default_pricegroup_id",
                            type: "select",
                            label: t("Új ügyfél alapértelmezett árkategória besorolása"),
                            load: process.env.REACT_APP_API_END_POINT + "/pricegroup/active",
                            loadValue: "id",
                            loadLabel: "name"
                        }           
                    ]     
                }, 
                {
                    label: t("Cikktörzs"),
                    children: [
                        {
                            type: "checkbox",
                            label: t("Azonos azonosító engedélyezése"),
                            id: "product_same_sku"
                        }           
                    ]     
                }, 
                {
                    label: t("Naplózás"),
                    children: [
                        {
                            id: "log_user",
                            type: "checkbox",
                            label: t("Felhasználók ki-belépéseinek naplózása")
                        },   
                        {
                            id: "log_product_Stock_movement",
                            type: "checkbox",
                            label: t("Cikkek készletmozgatásának naplózása")
                        }                
                    ]
                }, 
                {
                    label: t("Számlázás"),
                    children: [
                        {
                            id: "billingo_apikey",
                            type: "text",
                            label: t("Billingo V3 API kulcs")
                        }              
                    ]
                }                
            ]
        }
    ];
    return (<><h4>{t("Beállítások")}</h4><Formgenerator id={id} module={module} action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} fields={fields} /></>);
};

export default { Edit };
