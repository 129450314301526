import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";
import types from "./types.json";
import payment_methods from "./payment_methods.json";
import partner_address_types from "./partner_address_types.json";
import { setValue } from "../../components/Form/copyvalue";
import axios from "axios";
import { useState, useEffect } from "react";
// import { throwError } from "../../components/Error";
// import { useRef } from "react";

const module = "partner";
const url = "/partner";

const List = () => {   
    const { t } = useTranslation();        
    const title = t("Ügyféltörzs");

    const fields = [
        {
            id: "name",
            label: t("Név"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "type",
            label: t("Típus"),
            searchable: true,
            searchtype: "select",
            data: types.map((item) => {
                return {value: item.id, label: t(item.name)}
            })
        },
        {
            id: "price_category",
            label: t("Árkategória"),
            searchable: true,
            searchtype: "select",
            load: process.env.REACT_APP_API_END_POINT + "/pricegroup/active",
            loadValue: "id",
            loadLabel: "name"
        },
        {
            id: "address",
            label: t("Székhely"),
            searchable: true,
            searchlike: "full",
            hide: true
        },
        {
            id: "status",
            label: t("Aktív"),
            searchable: true,
            searchtype: "select",
            data: [
                {value: 1, label: t("Igen")},
                {value: 0, label: t("Nem")}
            ]
        },
        {
            id: "tax_number",
            label: t("Adószám"),
            searchable: true,
            searchlike: "like",
        },
        {
            id: "phone_number",
            label: t("Telefonszám"),
            searchable: true,
            searchlike: "like",
        },
        {
            id: "email",
            label: t("E-mail"),
            searchable: true,
            searchlike: "like",
        },
        {
            id: "web",
            label: t("Weboldal"),
            searchable: true,
            searchlike: "like",
            hide: true
        },
        {
            id: "note",
            label: t("Megjegyzés"),
            searchable: true,
            searchlike: "like",
            fullText: true,
            hide: true
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },        
        {
            id: "_functions_"
        }
    ];

    return (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
     />);
};
const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        if (id === 0) {
            axios.get(process.env.REACT_APP_API_END_POINT + "/setting/1").then((response) => {
                if (response.data.success) {
                    setSettings(response.data.response.data);
                }
            });
        }
    }, []);
    
    if (!settings && id === 0) return <></>;
    const fields = [
        {
            id: "tab-base",
            type: "tab",
            children: [
                {
                    label: t("Általános"),
                    children: [
                        {
                            id: "name",
                            type: "text",
                            label: t("Név"),
                            required: true,
                        },
                        {
                            id: "type",
                            label: t("Típus"),
                            required: true,
                            type: "select",
                            options: types.map((item) => {
                                return {value: item.id, label: t(item.name)}
                            })
                        },
                        {
                            id: "tax_number",
                            label: t("Adószám"),
                            type: "text",
                            validate: "taxnumber",
                        },
                        {
                            id: "phone_number",
                            label: t("Telefonszám"),
                            type: "text",
                            group: [
                                {
                                    id: "email",
                                    label: t("E-mail"),
                                    type: "email",
                                },
                                {
                                    id: "web",
                                    label: t("Weboldal"),
                                    type: "url",
                                },
                            ]            
                        },
                        {
                            id: "note",
                            label: t("Megjegyzés"),
                            type: "textarea",
                        },
                        {
                            id: "status",
                            label: t("Aktív"),
                            type: "checkbox",
                        }                        
                    ]
                },
                {
                    label: t("Besorolás"),
                    children: [
                        {
                            id: "price_category",
                            label: t("Árkategória"),
                            type: "select",
                            load: process.env.REACT_APP_API_END_POINT + "/pricegroup/active",
                            loadValue: "id",
                            loadLabel: "name",
                            value: {value: settings && settings.partner_default_pricegroup_id},
                        },
                        {
                            divider: t("Fizetési napok"),                            
                            id: "day_transfer",
                            label: t("Átutalás"),
                            append: t("nap"),
                            type: "number",
                            defaultValue: settings && settings.default_transfer_day,
                            group: [
                                {
                                    id: "day_cod",
                                    label: t("Utánvét"),
                                    type: "number",
                                    append: t("nap")
                                },
                                {
                                    id: "day_check",
                                    label: t("Csekk"),
                                    type: "number",
                                    append: t("nap")
                                },
                            ]
                        },
                        {
                            id: "vat_id",
                            label: t("Áfa kulcs"),
                            type: "select",
                            load: process.env.REACT_APP_API_END_POINT + "/vat/active",
                            loadValue: "id",
                            loadLabel: "name",
                            group: [
                                {
                                    id: "currency",
                                    label: t("Devizanem"),
                                    type: "select",
                                    load: process.env.REACT_APP_API_END_POINT + "/currency/active",
                                    loadValue: "currency",
                                    loadLabel: "currency",
                                }
                            ]
                        },
                        {
                            id: "payment_method",
                            label: t("Alapértelmezett fizetési mód"),
                            type: "select",
                            options: payment_methods.map((item) => {
                                return {value: item.id, label: t(item.name)}
                            })
                        },
                        {
                            id: "company_bankaccount_xref",
                            label: t("Partnerhez rendelt bankszámlák"),
                            type: "select",
                            multiple: true,
                            load: process.env.REACT_APP_API_END_POINT + "/bankaccount/active",
                            loadValue: "id",
                            loadLabel: "number",
                        },
                    ]
                },
                {
                    label: t("Címek"),
                    children: [{
                    type: "custom",
                    content: id === 0 ? t("A funkció használatához előbb mentsd el az űrlapot!") : <Listgenerator edit={{fields: [
                        {
                            id: "partner_id",
                            type: "hidden",
                            value: id
                        },                        
                        {
                            id: "type",
                            label: t("Típus"),
                            type: "select",
                            options: partner_address_types,
                            required: true
                        },
                        {
                            id: "country",
                            label: t("Ország"),
                            type: "select",
                            load: process.env.REACT_APP_API_END_POINT + "/city/countries",
                            loadValue: "country",
                            loadLabel: "country",
                            group: [
                                {
                                    id: "zip",
                                    label: t("IRSZ"),
                                    type: "select",
                                    load: process.env.REACT_APP_API_END_POINT + "/city/zips",
                                    loadValue: "zip",
                                    loadLabel: "zip",
                                    _onchange: (field) => {
                                        if (field.value) {
                                            setValue("city", field.loaddata.find((item) => item.zip === field.value.value).city);
                                        }
                                        return field;
                                    }
                                }
                            ]
                        },
                        {
                            id: "city",
                            label: t("Település"),
                            type: "text",
                            group: [
                                {
                                    id: "street",
                                    label: t("Utca, hsz."),
                                    type: "text",
                                }   
                            ]
                        },                        
                        {
                            id: "note",
                            label: t("Megjegyzés"),
                            type: "textarea",
                        }
                        ], title: "Címek", action: process.env.REACT_APP_API_END_POINT + "/partner_address/" + id}}
                        module="partner_address"
                        action={process.env.REACT_APP_API_END_POINT + "/partner_address/active/" + id + "/:id"} 
                        deleteurl={process.env.REACT_APP_API_END_POINT + "/partner_address/" + id + "/:id"} 
                        paginationServer 
                        columnSearch 
                        title="Címek" 
                        columns={
                            [
                                {
                                    id: "type",
                                    label: t("Típus"),
                                    searchable: true,
                                    searchlike: "select",
                                    data: partner_address_types
                                },
                                {
                                    id: "zip",
                                    label: t("IRSZ"),
                                    searchable: true,
                                    searchlike: "full",
                                },
                                {
                                    id: "city",
                                    label: t("Település"),
                                    searchable: true,
                                    searchlike: "full",
                                },
                                {
                                    id: "street",
                                    label: t("Utca, hsz."),
                                    searchable: true,
                                    searchlike: "full",
                                },
                                {
                                    id: "note",
                                    label: t("Megjegyzés"),
                                    searchable: true,
                                    searchlike: "full",
                                    hide: true,
                                    fullText: true
                                },
                                {
                                    id: "_functions_"
                                }
                            ]
                        }
                        buttons={[
                            {add: {url: "", label: "+ Új létrehozása"}}
                        ]}
                        />
                    }]
                },
                {
                    label: t("Bankszámlák"),
                    children: [{
                    type: "custom",
                    content: id === 0 ? t("A funkció használatához előbb mentsd el az űrlapot!") : <Listgenerator edit={{fields: [
                        {
                            id: "partner_id",
                            type: "hidden",
                            value: id
                        },                        
                        {
                            id: "number",
                            label: t("Számlaszám"),
                            type: "text",
                            validate: "bankaccount",
                        },
                        {
                            id: "currency",
                            label: t("Devizanem"),
                            type: "select",           
                            load: process.env.REACT_APP_API_END_POINT + "/currency/active",
                            loadValue: "currency",
                            loadLabel: "currency",                                    
                        },                                
                        {
                            id: "iban",
                            label: t("IBAN"),
                            type: "text"
                        },
                        {
                            id: "priority",
                            label: t("Elsődleges"),
                            type: "checkbox",
                        },
                        {
                            id: "swift",
                            label: t("Swift/BIC kód"),
                            type: "text",
                        },
                        {
                            id: "bank_name",
                            label: t("Bank neve"),
                            type: "text",
                        },
                        {
                            id: "note",
                            label: t("Megjegyzés"),
                            type: "textarea",
                        },
                        {
                            id: "debtright",
                            label: t("Inkasszó jog"),
                            type: "checkbox",
                        }
                        ], title: "Bankszámlák", action: process.env.REACT_APP_API_END_POINT + "/partner_bankaccount/" + id}}
                        module="partner_bankaccount"
                        action={process.env.REACT_APP_API_END_POINT + "/partner_bankaccount/active/" + id + "/:id"} 
                        deleteurl={process.env.REACT_APP_API_END_POINT + "/partner_bankaccount/" + id + "/:id"} 
                        paginationServer 
                        columnSearch 
                        title="Bankszámlák" 
                        columns={
                            [
                                {
                                    id: "number",
                                    label: t("Számlaszám"),
                                    searchable: true,
                                    searchlike: "full",
                                },
                                {
                                    id: "currency",
                                    label: t("Devizanem"),
                                    searchable: true,
                                    searchtype: "select",
                                    load: process.env.REACT_APP_API_END_POINT + "/currency/active",
                                    loadValue: "currency",
                                    loadLabel: "currency",            
                                },                                
                                {
                                    id: "iban",
                                    label: t("IBAN"),
                                    searchable: true,
                                    searchlike: "full",
                                },
                                {
                                    id: "priority",
                                    label: t("Elsődleges"),
                                    searchable: true,
                                    searchtype: "select",
                                    data: [
                                        {value: 1, label: t("Igen")},
                                        {value: 0, label: t("Nem")}
                                    ]
                                },
                                {
                                    id: "swift",
                                    label: t("Swift/BIC kód"),
                                    searchable: true,
                                    searchlike: "full",
                                },
                                {
                                    id: "bank_name",
                                    label: t("Bank neve"),
                                    searchable: true,
                                    searchlike: "full",
                                },
                                {
                                    id: "note",
                                    label: t("Megjegyzés"),
                                    searchable: true,
                                    searchlike: "full",
                                    hide: true,
                                    fullText: true
                                },
                                {
                                    id: "debtright",
                                    label: t("Inkasszó jog"),
                                    searchable: true,
                                    searchtype: "select",
                                    data: [
                                        {value: 1, label: t("Igen")},
                                        {value: 0, label: t("Nem")}
                                    ]
                                },
                                {
                                    id: "_functions_"
                                }
                            ]
                        }
                        buttons={[
                            {add: {url: "", label: "+ Új létrehozása"}}
                        ]}
                        />
                    }]
                }
            ]
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{afterSubmit: () => cancelSplitScreen()}}
    />);
};

export default { Edit, List }