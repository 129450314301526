import Formgenerator from "../../components/Form";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { throwError } from "../../components/Error";

const module = "company";
// const url = "/company";

const MyData = ({id = 1}) => {
    const { t } = useTranslation();

    const checkTax = (e) => {
        const tax = document.getElementById("tax_number").value;
        if (!tax) return;

        // regexp check
        if (!/^[0-9]{8}-[1-5]-[0-9]{2}$/.test(tax)) {
            throwError({data: {success: false, message: t("Hibás adószám formátum")}});
            return;
        }

        e.target.disabled = true;
        e.target.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${t("Ellenőrzés")}`;

        const fields = ["name", "address_zip", "address_city", "address_street", "tax_number"];
        fields.forEach((field) => {
            document.getElementById(field).disabled = true;
        });

        axios.get(process.env.REACT_APP_API_END_POINT + `/company/checktax/${tax}`)
            .then((response) => {
                throwError(response);
                if (response.data.success) {
                    fields.forEach((field) => {
                        document.getElementById(field).disabled = false;
                        const input = document.getElementById(field);
                        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype,'value').set;
                        nativeInputValueSetter.call(input, response.data.response.data[field]);
                        const event = new Event('input', { bubbles: true });
                        input.dispatchEvent(event);                                       
                    });                    
                }
                e.target.disabled = false;
                e.target.innerHTML = t("Ellenőrzés");
            }
        );
    }    

    const fields = [
        {
            id: "tax_number",
            type: "text",
            label: t("Adószám"),
            required: true,
            validate: "tax",
            append: <button type='button' className='btn btn-primary' onClick={checkTax}>{t("Ellenőrzés")}</button>
        },        
        {
            id: "name",
            type: "text",
            label: t("Cégnév"),
            required: true,
        },
        {
            id: "note",
            type: "text",
            label: t("Cég megjegyzés"),
        },
        {
            id: "address_zip",
            type: "text",
            label: t("Számlázási irányítószám"),
            group: [
                {
                    id: "address_city",
                    type: "text",
                    label: t("Település"),
                },
                {
                    id: "address_street",
                    type: "text",
                    label: t("Utca, házszám"),
                }
            ]
        },
        {
            id: "vat_number",
            type: "text",
            label: t("Cégjegyzékszám"),
        },
        {
            id: "phone_number",
            type: "text",
            label: t("Telefonszám"),
        },
        {
            id: "email",
            type: "email",
            label: t("E-mail"),
        },
        {
            id: "web",
            type: "text",
            label: t("Weboldal"),
        }
    ];
    return (<><h2 className="fw-light text-uppercase mb-3">Cégadatok</h2><Formgenerator id={id} module={module} action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} fields={fields} /></>);
};

export default { MyData };
