import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";

const module = "location";
const url = "/company/location";

const List = () => {   
    const { t } = useTranslation();        
    const title = t("Telephelyek");

    const fields = [
        {
            id: "name",
            label: t("Név"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "address",
            label: t("Cím"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "billing_address",
            label: t("Számlázási cím"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "status",
            label: t("Aktív"),
            searchable: true,
            searchtype: "select",
            data: [
                {value: 1, label: t("Igen")},
                {value: 0, label: t("Nem")}
            ]
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },
        {
            id: "_functions_"
        }
    ];

    return (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
     />);
};
const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    
    const fields = [
        {
            id: "name",
            type: "text",
            label: t("Megnevezés"),
            required: true,
        },
        {
            id: "address",
            type: "text",
            label: t("Cím"),
            required: true,
        },
        {
            id: "billing_address_zip",
            type: "text",
            label: t("Számlázási irányítószám"),
            group: [
                {
                    id: "billing_address_country",
                    type: "text",
                    label: t("Számlázási ország"),
                    // required: true,
                    value: "Magyarország",
                },
                {
                    id: "billing_address_city",
                    type: "text",
                    label: t("Számlázási település"),
                    // required: true,
                },
                {
                    id: "billing_address_address",
                    type: "text",
                    label: t("Számlázási cím"),
                    // required: true,
                }
            ]
        },
        {
            id: "status",
            type: "checkbox",
            label: t("Aktív?"),
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{afterSubmit: () => cancelSplitScreen()}}    
    />);
};

export default { Edit, List }