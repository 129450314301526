import { Link } from "react-router-dom";

function Fzf() {
    return (
        <div className="container d-flex align-items-center justify-content-center vh-100">
            <section className="text-uppercase fs-1">
                <Link to="/">404</Link>
            </section>
        </div>
    );
}

// Fzf.network = () => {
//     return (
//         <div className="container d-flex align-items-center justify-content-center vh-100">
//             <section className="text-uppercase fs-1">
//                 <Link to="/">Network Error</Link>
//             </section>
//         </div>
//     );
// }

export default Fzf;