import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [remember, setRemember] = useState(JSON.parse(localStorage.getItem("remember")));
    const [token, setToken] = useState(remember ? localStorage.getItem("token") : sessionStorage.getItem("token"));
    
    useEffect(() => {
        const componentDidCatch = (error, errorCode) => {
            console.error(errorCode, error);
            if (errorCode === 401) {
                setToken();
                return <Navigate to="/logout" />
            }
        }
        window.componentDidCatch = componentDidCatch;

        return () => {
            delete window.componentDidCatch;
        }
    }, [])

    // const remember = JSON.parse(localStorage.getItem('remember'));
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        remember ? localStorage.setItem("token", token) : sessionStorage.setItem("token", token);
    } else {
        delete axios.defaults.headers.common["Authorization"];
        remember ? localStorage.removeItem("token", token) : sessionStorage.removeItem("token", token);
    }    

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            setRemember,
        }),
        [token]
    );    

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;