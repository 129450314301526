import { useUserData }  from '../../provider/userProvider';

const Permission = ({permission, permissions}) => {
    const { user } = useUserData();
    if (permissions) {
        return (!permission || (user.permissions && (user.permissions.includes(`*`) || user.permissions.some(p => permissions.includes(p)))));
    }
    return (!permission || (user.permissions && (user.permissions.includes(`*`) || user.permissions.includes(permission))));
}

export default Permission;