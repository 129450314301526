import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


function Rightside({show, close, title, body, ...props}) {

    return <Offcanvas show={show} placement="end" backdrop="static" scroll={false} className={props.className} autoFocus>
        <Offcanvas.Header className='align-items-center border-bottom'>
            <div className="d-flex align-items-center w-100">
                <Offcanvas.Title className='flex-grow-1 display-xs-semibold'>{title}</Offcanvas.Title>
                <FontAwesomeIcon icon={faTimes} role="button" onClick={close} />
            </div>
        </Offcanvas.Header>
        <Offcanvas.Body>{body}</Offcanvas.Body>
    </Offcanvas>;

}


export default Rightside;