import { RouterProvider, createBrowserRouter, Route, createRoutesFromElements, Navigate, useRouteError } from 'react-router-dom';
import { MainContent } from "../components/Container";
import { useAuth } from "../provider/authProvider";
import UserProvider from "../provider/userProvider";

// Default modules
import { Login, Logout, AfterLogin } from "../pages/login";
import Fzf from "../pages/error/404";

// Menus
import pages from "../pages.json";

const Router = () => {
    const { token } = useAuth();
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                <Route path="*" element={token ? <Fzf /> : <Navigate to={`/?redirect=${window.location.pathname}`} errorElement={ <ErrorBoundary /> } />} />
                {token ? <Route path="/" element={<AfterLogin />} /> : <Route path="/" element={<Login />} errorElement={ <ErrorBoundary /> } />}
                {token && 
                    <>
                        <Route path="/logout" element={<Logout />} />
                        {pages.map((item, i) => {
                            try {                                
                                let DynamicComponent = item.index.folder ? require('../pages/' + item.index.folder.toLowerCase() + '/' + item.index.name.toLowerCase()).default : require('../pages/' + item.index.name.toLowerCase()).default;
                                return (
                                        <Route key={"route" + i} path={item.index.url} element={ <UserProvider><MainContent /></UserProvider> } errorElement={ <ErrorBoundary /> }>
                                            <Route index Component={ DynamicComponent[item.index.subname] } errorElement={ <ErrorBoundary /> } />
                                            {item.components ? item.components.map((subitem, subi) => {
                                                return <Route key={"route" + i + "subroute" + subi} path={subitem.url} Component={subitem.name ? require('../pages/' + item.index.name.toLowerCase() + '/' + subitem.name.toLowerCase()).default[subitem.subname] : DynamicComponent[subitem.subname] } errorElement={ <ErrorBoundary /> } />
                                            }) : 
                                                <Route key={"route" + i + "subroute"} Component={ DynamicComponent[item.index.subname] } errorElement={ <ErrorBoundary /> } />
                                            }
                                        </Route>
                                    );
                            } catch (e) {
                                return <Route key={"route" + i} path={item.index.url} element={<Fzf />} errorElement={ <ErrorBoundary /> } />;
                            }
                        })}
                    </>
                }
            </Route>
        ), {basename: token && process.env.REACT_APP_ROUTERBASENAME}
    );
    return <RouterProvider router={router} />;
};


function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    return <div className="container d-flex align-items-center justify-content-center vh-100"><div className="alert alert-danger">Modul betöltési hiba... Bővebb infó a console.log() -ban.</div></div>;
}

export default Router;
