import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";

const module = "bankaccount";
const url = "/company/bankaccount";

const List = () => {   
    const { t } = useTranslation();        
    const title = t("Bankszámlaszámok");

    const fields = [
        {
            id: "number",
            label: t("Számlaszám"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "iban",
            label: t("IBAN"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "swift",
            label: t("Swift/BIC kód"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "bank_name",
            label: t("Bank neve"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "bank_address",
            label: t("Bank címe"),
            searchable: true,
            searchlike: "full",
            hide: true
        },
        {
            id: "note",
            label: t("Megjegyzés"),
            searchable: true,
            searchlike: "full",
            hide: true,
            fullText: true
        },
        {
            id: "currency_id",
            label: t("Devizanem"),
            searchable: true,
            searchtype: "select",
            load: process.env.REACT_APP_API_END_POINT + "/currency/active",
            loadValue: "id",
            loadLabel: "currency",            
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },        
        {
            id: "_functions_"
        }
    ];

    return (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
     />);
};
const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    
    const fields = [
        {
            id: "number",
            type: "text",
            label: t("Számlaszám"),
            validate: "banknumber",
            required: true,
        },
        {
            id: "iban",
            type: "text",
            label: t("IBAN"),
            required: true,
        },
        {
            id: "swift",
            type: "text",
            label: t("Swift/BIC kód"),
            required: true,
        },
        {
            id: "bank_name",
            type: "text",
            label: t("Bank neve"),
            required: false,
        },
        {
            id: "bank_address",
            type: "text",
            label: t("Bank címe"),
            required: false,
        },
        {
            id: "currency_id",
            type: "select",
            label: t("Devizanem"),
            required: true,
            load: process.env.REACT_APP_API_END_POINT + "/currency/active",
            loadValue: "id",
            loadLabel: "currency",
        },        
        {
            id: "note",
            type: "textarea",
            label: t("Megjegyzés"),
            required: false,
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{afterSubmit: () => cancelSplitScreen()}}
    />);
};

export default { Edit, List }