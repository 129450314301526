import { Table, Pagination, Toggle, List, Checkbox, SelectPicker, Input, Form, InputGroup } from 'rsuite';
import axios from 'axios';
import { useQuery, useQueries } from '@tanstack/react-query';
import showToast from './../Toast';
import { Link, useParams } from "react-router-dom";
import { useState, useMemo, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faTrash, faSpinner, faCog, faSearch, faTimes, faCogs } from '@fortawesome/free-solid-svg-icons'
import Formgenerator from "../Form";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { throwError } from "../Error";
import Rightside from "../Rightside";
import Storage from '../../routes/storage';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { IsMobile } from '../Device';
import Permission from '../Permission';
import { useTranslation } from 'react-i18next';
// import { Allotment } from "allotment";
// import "allotment/dist/style.css";
import Avatar from '../Avatar';
import { useHotkeys } from 'react-hotkeys-hook'

library.add(fas);



// Jobb oldali szerkesztő
function ListOffCanvas({ title, action, fields, ...props }) {
    const { t } = useTranslation();
    const { showOffcanvas, setOffcanvas, fetchData } = props;
    const formRef = useRef();

    // const onSubmit = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     let data = {};
    //     for (let i = 0; i < event.target.elements.length; i++) {
    //         if (event.target.elements[i].type === "submit") continue;
    //         data[event.target.elements[i].id] = event.target.elements[i].value;
    //     }
    //     // console.log(data);
    //     // console.log(formRef);
    //     return false;
    //     axios.request({
    //         method: showOffcanvas[1] ? "put" : "post",
    //         url: action.replace(":id", showOffcanvas[1]),
    //         data: data
    //     }).then(function (response) {
    //         setOffcanvas([false, 0]);
    //         // fetchData();
    //         throwError(response);
    //     })
    //         .catch(function (error) {
    //             showToast.error(error);
    //         });
    // }

    return <Rightside show={showOffcanvas[0]} close={() => setOffcanvas([false, 0])} title={title ? title : t(`Szerkesztés`)} className={props.className ? props.className : ""} body={
        <Formgenerator
            ref={formRef}
            id={showOffcanvas[1]}
            module={props.module}
            action={action.replace(":id", showOffcanvas[1])}
            cancelurl={(e) => setOffcanvas([false, 0])}
            fields={fields}
            options={{
                beforeSubmit: (data, event) => {
                    if (props.options && props.options.beforeSubmit) props.options.beforeSubmit(data, event, setOffcanvas);
                    event.stopPropagation();
                },
                afterSubmit: () => {
                    if (props.options && props.options.afterSubmit) props.options.afterSubmit();
                    setOffcanvas([false, 0]);
                    fetchData();
                }
            }}
        />
    } />
}

function Listgenerator({ title, url, columns, buttons, action, deleteurl, edit, paginationServer, module, SplitScreen, tableSettings, fromPlace, _data, ...props }) {
    const { t } = useTranslation();
    let [data, setData] = useState(_data ? _data : {});
    const [showSplitScreen, setSplitScreen] = useState(-1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(-1);
    // const [showOffcanvas, setOffcanvas] = useState([false, 0]);
    const [showOffcanvas, setOffcanvas] = useState(props.showOffcanvas ? props.showOffcanvas : [false, 0]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(paginationServer ? (Storage.get("pageData", action.replace(":id", "")) ? Storage.get("pageData", action.replace(":id", "")).page : 1) : -1);
    const [perPage, setPerPage] = useState(Storage.get("pageData", action.replace(":id", "")) ? Storage.get("pageData", action.replace(":id", "")).perPage : (props.paginationPerPage ? props.paginationPerPage : 100));
    const [sortColumn, setSortColumn] = useState(Storage.get("sortData", action.replace(":id", "")) ? Storage.get("sortData", action.replace(":id", "")).sortColumn : (columns.length > 0 ? columns[0].id : ""));
    const [sortType, setSortType] = useState(Storage.get("sortData", action.replace(":id", "")) ? Storage.get("sortData", action.replace(":id", "")).sortType : "asc");
    const [searchValue, setSearchValue] = useState("");
    const filterValues = useMemo(() => ({
        filter: {},
        lastId: ""
    }), []);
    const { id } = useParams();
    if (tableSettings === undefined) tableSettings = true;

    const cancelSplitScreen = (event) => {
        if (!event) {
            setIsLoaded(false);
            return setSplitScreen(-1);
        }

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            icon: "error",
            text: t("Biztosan kilépsz mentés nélkül?"),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: t('Igen'),
            cancelButtonText: t('Mégsem'),
            confirmButtonColor: '#d33',
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                setSplitScreen(-1);
            }
        })
    }

    const Hotkeys = () => {
        // Hotkeys - Új gomb (ha nincs megnyitva semmi, akkor újat hozunk létre)
        useHotkeys('insert', () => {
            if (SplitScreen && showSplitScreen < 1 && buttons.some(button => button.add) && <Permission permissions={["POST_" + module]} />) setSplitScreen(0)
        });
        // Hotkeys - Keresés (ha meg van nyitva már egy, akkor bezárjuk)
        useHotkeys('escape', (e) => {
            if (showOffcanvas[0]) {
                e.preventDefault();
                e.stopPropagation();
                setOffcanvas([false, 0]);
                return false;
            }
            if (SplitScreen && showSplitScreen > -1) cancelSplitScreen(e)
        });
        // Hotkeys - Törlés (ha meg van nyitva már egy, akkor töröljük)
        useHotkeys('delete', () => {
            if (SplitScreen && showSplitScreen > 0 && <Permission permissions={["DELETE_" + module]} />) deleteRow(action, showSplitScreen)
        });
        // Hotkeys - up (előző elemre ugrás, ha meg van nyitva már egy)
        useHotkeys('up', () => {
            if (SplitScreen && showSplitScreen > -1) {
                let next = data.findIndex(item => item.id == showSplitScreen) - 1;
                if (next >= 0) setSplitScreen(data[next].id);
            }
        });
        // Hotkeys - down (következő elemre ugrás, ha meg van nyitva már egy)
        useHotkeys('down', () => {
            if (SplitScreen && showSplitScreen > -1) {
                let next = data.findIndex(item => item.id == showSplitScreen) + 1;
                if (next < data.length) setSplitScreen(data[next].id);
            }
        });
        // Hotkeys - left (előző oldalra ugrás)
        useHotkeys('left', () => {
            if (showSplitScreen < 1 && page > 1) setPage(page - 1);
        });
        // Hotkeys - right (következő oldalra ugrás)
        useHotkeys('right', () => {
            if (showSplitScreen < 1 && page < Math.ceil(totalRows / perPage)) setPage(page + 1);
        });
    }

    // Hotkeys
    Hotkeys();

    // Splitscreen auto open
    useEffect(() => {
        if (SplitScreen) {
            if (id && !isNaN(id)) setSplitScreen(id);
        }
    }, [SplitScreen]);

    // Splitscreen URL settings
    useEffect(() => {
        if (SplitScreen) {
            window.history.pushState({}, "", `${url}${showSplitScreen > 0 ? "/" + showSplitScreen : ""}`);
        }
    }, [showSplitScreen, SplitScreen]);

    // Oszlopnál külső adat betöltése
    const options = useQueries({
        queries: columns.filter(column => column.load).map((column) => ({
            queryKey: ["options", column.load],
            queryFn: () => {
                return axios.get(column.load + "?page=-1").then((response) => {
                    if (response.data.success) {
                        column.data = response.data.response.data.map((item) => {
                            return { value: item[column.loadValue], label: item[column.loadLabel] };
                        });
                        delete column.load;
                        return column;
                    }
                    throwError(response);
                });
            },
            refetchOnWindowFocus: false
        }))
    });

    // Default pagedata mentése
    Storage.add("pageData", { [action.replace(":id", "")]: { page: page, perPage: perPage } });

    // Egy oldalon megjelenítés módosítása
    const handleChangeLimit = dataKey => {
        setIsLoaded(false);
        setPage(1);
        setPerPage(dataKey);
    };

    // Oszlop sorrendek módosítása
    const handleSortColumn = (newSortColumn, newSortType) => {
        setIsLoaded(false);
        setSortColumn(newSortColumn);
        setSortType(newSortType);
        Storage.add("sortData", { [action.replace(":id", "")]: { sortColumn: newSortColumn, sortType: newSortType } });
    };

    // Oszlop méretek módosítása
    const handleResize = (columnWidth, dataKey) => {
        // console.log(columnWidth);
        // console.log(dataKey);
        // Storage.add("resizeData", {[action.replace(":id", "")]: {columnWidth: columnWidth, sortType: newSortType}});
    };

    // Adatok lekérése
    const fetchData = () => {
        if (!action) return showToast.error(t("Nincs megadva az action attribútum, a lista nem kérhető le!"));
        let url = action.replace(":id", "");
        if (url.includes("?")) url += "&";
        else url += "?";
        url += `page=${page}&perpage=${perPage}&orderby=${sortColumn}&orderway=${sortType}&search=${searchValue}&filter=${JSON.stringify(filterValues.filter ? filterValues.filter : {})}`;
        return axios.get(url)
            .then(function (response) {
                setIsLoaded(true);
                if (response.data.success) {
                    setTotalRows(response.data.pager.count_rows);
                    setData(response.data.response.data);
                }
                throwError(response);
                return response;
            }).catch(function (error) {
                showToast.error(error);
            });
    }

    useQuery({
        queryKey: [`${module}List`, action, page, perPage, sortColumn, sortType, searchValue, filterValues, isLoaded],
        queryFn: () => fetchData(),
        refetchOnWindowFocus: showSplitScreen > -1 ? false : true
    });


    // Törlés modal
    function deleteRow(action, id, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (!action) return showToast.error(t("Nincs megadva az action attribútum, az url nem kérhető le!"));

        function doDelete(id) {
            setPendingDelete(id);
            axios.delete(deleteurl.replace(":id", id))
                .then(function (response) {
                    throwError(response);
                    setPendingDelete(-1);
                    cancelSplitScreen();
                    fetchData();

                })
                .catch(function (error) {
                    setPendingDelete(-1);
                    cancelSplitScreen();
                    showToast.error(error);
                });
        }

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            icon: "error",
            text: t("Biztosan töröljük?"),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: t('Igen'),
            cancelButtonText: t('Mégsem'),
            confirmButtonColor: '#d33',
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                doDelete(id);
            }
        })

    }

    columns = Object.keys(columns).map((id) => {
        const column = columns[id];
        id = column.id;
        if (column.sortable === undefined) column.sortable = true;
        if (column.hideable === undefined) column.hideable = true;
        if (column.format) {
            if (column.format === "yyyy-mm-dd") {
                column.cell = (rowData) => {
                    return new Intl.DateTimeFormat('hu-HU').format(new Date(rowData[id]));
                }
            }
            else if (column.format === "user") {
                column.cell = (rowData) => {
                    return rowData[id] ? <Avatar id={rowData[id].id} name={rowData[id].name} image={rowData[id].photo} /> : "";
                }
            }
        }
        if (id === "_functions_") {
            if (!column.label) column.label = t("");
            column.hideable = false;

            // Check permission
            if (!<Permission permissions={["PUT_" + module, "DELETE_" + module]} />) return false;
        }
        return column;
    });
    columns = columns.filter(column => column !== false);

    // Check columndata ugyanaz, mint most és akkor lekérjük storageból, ha nem, akkor törüljök ott
    let columnData = Storage.get("columnData", action.replace(":id", ""));
    if (columnData) {
        columns = columnData.map(column => {
            let newColumn = columns.find(newColumn => newColumn.id === column.id);
            if (newColumn) {
                newColumn.hide = column.hide;
            }
            return newColumn;
        });
    }

    if (data && Object.keys(data).length > 0) {
        data = Object.keys(data).map((id) => {
            return { id, ...data[id] };
        });
    }
    if (!buttons) buttons = {};


    function TableSettings({ columns, onChange }) {
        const [show, setShow] = useState(false);

        // Oszlop elrejtés
        const handleHideColumn = (checked, event) => {
            let newColumns = columns.map(column => {
                if (column.id === event.target.id.replace("columnHideSetting", "")) {
                    column.hide = !checked ? true : false;
                }
                return column;
            });
            onChange(newColumns);
            Storage.add("columnData", {
                [action.replace(":id", "")]: newColumns.map(column => {
                    return { id: column.id, hide: column.hide };
                })
            });
        }

        // Oszlop mozgatás
        const handleSortColumn = (event) => {
            const { oldIndex, newIndex } = event;
            const newColumns = [...columns];
            const item = newColumns.splice(oldIndex, 1)[0];
            newColumns.splice(newIndex, 0, item);
            onChange(newColumns);
            Storage.add("columnData", {
                [action.replace(":id", "")]: newColumns.map(column => {
                    return { id: column.id, hide: column.hide };
                })
            });

        }

        let columnData = Storage.get("columnData", action.replace(":id", "")) || columns.map(column => {
            return { id: column.id, hide: column.hide };
        });
        return <div className="position-relative">
            <Button variant="light" type="button" onClick={() => setShow(!show)}><FontAwesomeIcon icon={faCog} /></Button>
            <div className="position-absolute p-2 text-start rounded" style={{ zIndex: 99, width: "250px", left: "-200px", display: show ? "block" : "none" }}>
                <List size="sm" sortable bordered pressDelay={100} onSort={handleSortColumn}>
                    {columns.filter(column => column.hideable !== false).map((column, i) => {
                        return <List.Item key={i} index={i}><Toggle size="sm" id={column.id + "columnHideSetting"} onChange={handleHideColumn} defaultChecked={columnData.find(newColumn => newColumn.id === column.id) ? !columnData.find(newColumn => newColumn.id === column.id).hide : true} disabled={column.hideable !== true ? true : false} className='me-2'/> {column.label}</List.Item>
                    })}
                </List>
            </div>
        </div>
    }

    function RenderTableContainer({ columns, data, selectableRows, onSelectedRowsChange, globalSearch, columnSearch, buttons, _onRowClick, ...props }) {
        const [_columns, setColumns] = useState(columns);
        const [checkedKeys, setCheckedKeys] = useState([]);

        let checked = false;
        let indeterminate = false;

        const handleCheckAll = (value, checked) => {
            const keys = checked ? data.map(item => item.id) : [];
            onSelectedRowsChange(keys);
            setCheckedKeys(keys);
        };
        const handleCheck = (value, checked) => {
            const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
            onSelectedRowsChange(keys);
            setCheckedKeys(keys);
        };
        if (data && checkedKeys.length === data.length) checked = true;
        else if (checkedKeys.length === 0) checked = false;
        else if (checkedKeys.length > 0 && checkedKeys.length < data.length) indeterminate = true;


        const RenderTable = () => {

            return <Table
                // fillHeight={SplitScreen ? true : false}
                fillHeight={false}
                loading={!isLoaded}
                data={data && Object.values(data)}
                locale={{ loading: "Pillanat...", emptyMessage: "Nincs adat" }}
                onSortColumn={handleSortColumn}
                sortColumn={sortColumn}
                sortType={sortType}
                onResize={handleResize}
                onRowClick={(data, event) => {
                    if (_onRowClick) {
                        let resp = _onRowClick(data, event);
                        if (resp === false) return;
                    }
                    if (SplitScreen) {
                        if (event.target.classList.value === "rs-table-cell-content") {
                            <Permission permission={"PUT_" + module} /> && setSplitScreen(data.id);
                        }
                    }
                    else if (edit) {
                        if (event.target.classList.value === "rs-table-cell-content") {
                            <Permission permission={"PUT_" + module} /> && setOffcanvas([true, data.id]);
                        }
                    }
                }}
                rowHeight={62.5}
                headerHeight={columnSearch ? 
                    window.innerWidth > 768 ? 125 : 55 :
                    window.innerWidth > 768 ? 60 : 40
                }
                rowClassName={(rowData, rowIndex) => {
                    return showSplitScreen > 0 && rowData && rowData.id == showSplitScreen ? "rs-table-row-custom active" : "rs-table-row-custom";
                }}
                {...props}
            >
                {(selectableRows && !IsMobile()) && <Table.Column flexGrow={0} width={50} verticalAlign="middle" align="center">
                    <Table.HeaderCell style={{ padding: 0 }}>
                        <Checkbox
                            inline
                            checked={checked}
                            indeterminate={indeterminate}
                            onChange={handleCheckAll}
                        />
                    </Table.HeaderCell>
                    <Table.Cell style={{ padding: 0 }}>
                        {rowData =>
                            <div style={{ lineHeight: '46px' }}>
                                <Checkbox
                                    value={rowData["id"]}
                                    inline
                                    onChange={handleCheck}
                                    checked={checkedKeys.some(item => item === rowData["id"])}
                                />
                            </div>
                        }
                    </Table.Cell>
                </Table.Column>}
                {_columns.filter(column => (column.hide !== true && column.id !== '_functions_')).map((column, i) => {
                    let { id, label, cell, hideable, hide, searchable, searchtype, searchlike, searchid, data, load, loadValue, loadLabel, ...rest } = column;
                    if (data) {
                        cell = (rowData) => {
                            let item = data.find(item => item.value == rowData[id]);
                            return item ? item.label : "";
                        }
                    }


                    let timeoutId = '';
                    function handleFilterChange(value, e) {
                        let delay = (e === undefined || e.type === "click" || value === null) ? 0 : 500;
                        if (timeoutId !== '') clearTimeout(timeoutId);
                        timeoutId = setTimeout(() => {
                            setIsLoaded(false);
                            if (value === "" || value === null) {
                                if (filterValues.filter[id]) delete filterValues.filter[id];
                            }
                            else {
                                filterValues.filter[id] = {
                                    type: searchtype ? searchtype : "text",
                                    like: searchlike ? searchlike : "end",
                                    id: searchid ? searchid : id,
                                    value: value
                                };
                                filterValues.lastId = id;
                            }
                            setPage(1);
                            fetchData();
                        }, delay);
                    }

                    return (
                        (columnSearch && searchable && !IsMobile()) ?
                            <Table.ColumnGroup key={id} header={column.searchtype === "select" ?
                                <SelectPicker
                                    size="sm"
                                    data={column.data ? column.data : []}
                                    placeholder={`${label} ${t("keresés...")}`}
                                    onChange={handleFilterChange}
                                    block={true}
                                    defaultValue={filterValues.filter[id] ? filterValues.filter[id].value : ""}
                                />
                                :
                                <Input
                                    size="sm"
                                    type="text"
                                    defaultValue={filterValues.filter[id] ? filterValues.filter[id].value : ""}
                                    placeholder={`${label} ${t("keresés...")}`}
                                    onKeyDown={(e) => { if (e.key === "Escape") { handleFilterChange(null); } }}
                                    onChange={handleFilterChange}
                                    autoFocus={filterValues.lastId === id}
                                />
                            }>
                                <Table.Column key={id} flexGrow={1} minWidth={window.innerWidth > 768 ? 200 : 120} verticalAlign="middle" {...rest}>
                                    <Table.HeaderCell>{label}</Table.HeaderCell>
                                    <Table.Cell dataKey={id}>{cell && (rowData => cell(rowData))}</Table.Cell>
                                </Table.Column>
                            </Table.ColumnGroup>
                            :
                            <Table.Column key={id} flexGrow={1} minWidth={window.innerWidth > 768 ? 200 : 120} verticalAlign="middle" {...rest}>
                                <Table.HeaderCell>{label}</Table.HeaderCell>
                                <Table.Cell dataKey={id}>{cell && (rowData => cell(rowData))}</Table.Cell>
                            </Table.Column>
                    );
                })}
                {_columns.some(column => column.id === "_functions_") &&
                    <Table.Column flexGrow={0} width={window.innerWidth > 768 ? 70 : 52} verticalAlign="middle" align="right">
                        <Table.HeaderCell>
                            <FontAwesomeIcon icon={faCogs} />
                            {_columns.find(column => column.id === "_functions_").label}
                        </Table.HeaderCell>
                        <Table.Cell>
                            {rowData =>
                                _columns.find(column => column.id === "_functions_").cell ?
                                    _columns.find(column => column.id === "_functions_").cell({ ...rowData, _listGenerator: { deleteRow, action: action } })
                                    :
                                    <>
                                        {<Permission permission={"DELETE_" + module} /> &&
                                            (<button type="button" className="btn btn-sm btn-danger" onClick={(event) => deleteRow(action, rowData.id, event)} disabled={pendingDelete === rowData.id ? true : false}><FontAwesomeIcon icon={pendingDelete === rowData.id ? faSpinner : faTrash} spin={pendingDelete === rowData.id ? true : false} /></button>)
                                        }
                                    </>
                            }
                        </Table.Cell>
                    </Table.Column>
                }
            </Table>
        }

        const RenderTablePagination = () => {
            return <div className={IsMobile() ? "mx-auto mt-2" : "float-end mt-3 mx-2 pl-2"}><Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks={IsMobile() ? false : true}
                maxButtons={IsMobile() ? 3 : 5}
                size="sm"
                layout={IsMobile() ? ['pager'] : ['total', '|', 'limit', 'pager']}
                total={totalRows}
                limitOptions={props.paginationLimits ? props.paginationLimits : [10, 30, 50, 100]}
                limit={perPage}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
                locale={{ total: 'Találatok: {0}', limit: '{0} / oldal', page: 'Oldal' }}
            /></div>
        }


        return <>
            <Row className="align-items-center w-100">
                <Col className="d-lg-flex gap-2 align-items-center justify-content-between mb-4">
                    <h2 className='mb-3 mb-lg-0 text-uppercase fw-light fs-2 me-3'>{title}</h2>
                    <div className='d-flex flex-wrap flex-md-nowrap justify-content-between justify-content-lg-end align-items-end align-items-md-center'>
                        {Object.values(buttons).map((field, id) => {
                            let key = Object.keys(field)[0];
                            field = field[key];
                            if (key === "add") {
                                if (!field.id) field.id = id;
                                if (!field.label) field.label = t("+ Új létrehozása");
                                return (
                                    <Permission permission={field.permission} /> &&
                                        edit ?
                                        <button type="button" key={field.id + `topButton`} className="btn btn-sm btn-primary text-uppercase me-3 text-nowrap" onClick={() => setOffcanvas([true, 0])}>{field.label}</button>
                                        :
                                        SplitScreen ?
                                            <button type="button" key={field.id + `topButton`} className="btn btn-sm btn-primary text-uppercase me-3 text-nowrap" onClick={() => setSplitScreen(0)}>{field.label}</button>
                                            :
                                            <Link key={field.id + `topButton`} className="btn btn-sm btn-primary mb-3 text-uppercase text-nowrap me-3" to={field.url}>{field.label}</Link>
                                );
                            }
                            else {
                                return (
                                    field.content ? field.content :
                                        <Link key={field.id + `topButton`} className={field.className} to={field.url}>
                                            {field.icon && <FontAwesomeIcon icon={field.icon} className="me-3" />}
                                            {field.label}
                                        </Link>

                                );
                            }
                        })}

                        <div className={buttons.length > 1 ? "d-flex" : "ms-auto d-flex"}>
                            <div>
                                {globalSearch &&
                                    <Form onSubmit={(value, event) => { event.preventDefault(); setIsLoaded(false); setSearchValue(event.target.elements[0].value); fetchData(); }}>
                                        <InputGroup>
                                            <Form.Control name="search" type="text" placeholder={t("Keresés...")} onKeyDown={(e) => { if (e.key === "Escape") { setIsLoaded(false); setSearchValue(""); fetchData(); } }} defaultValue={searchValue} />
                                            <InputGroup.Button>
                                                <FontAwesomeIcon icon={faSearch} />
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </Form>
                                }
                            </div>
                            {tableSettings && <div><TableSettings columns={_columns} onChange={setColumns} /></div>}
                        </div>

                    </div>

                </Col>
            </Row>
            <Row className="w-100 h-100">
                <Col><RenderTable /></Col>
            </Row>
            {paginationServer && <RenderTablePagination key="pagination" />}
            {SplitScreen &&
                <Modal show={showSplitScreen > -1 ? true : false} fullscreen={true} onHide={() => cancelSplitScreen()} keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{textTransform: "uppercase"}}>{props.title_add ? props.title_add : title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><SplitScreen id={showSplitScreen} cancelSplitScreen={cancelSplitScreen} setSplitScreen={setSplitScreen} /></Modal.Body>
                </Modal>
            }


        </>
    }

    return (
        <>

            <RenderTableContainer buttons={buttons} columns={columns} data={data} {...props} />
            {edit && <ListOffCanvas key={JSON.stringify(edit)} title={edit.title} action={edit.action} fields={edit.fields} showOffcanvas={showOffcanvas} setOffcanvas={setOffcanvas} fetchData={fetchData} module={module} {...edit} />}
        </>
    );
}


export default Listgenerator;