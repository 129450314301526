class Storage {
    static add(field, data, type = "session") {
        let storage = this.get(field, null, type) || {};
        data = Object.assign(storage, data);
        if (type === "session") sessionStorage.setItem(field, JSON.stringify(data));
        else if (type === "local") localStorage.setItem(field, JSON.stringify(data));
    }
    static set(field, data, type = "session") {
        if (type === "session") sessionStorage.setItem(field, JSON.stringify(data));
        else if (type === "local") localStorage.setItem(field, JSON.stringify(data));
    }
    static get(field, key, type = "session") {
        let data = JSON.parse(type === "session" ? sessionStorage.getItem(field) : localStorage.getItem(field));
        if (key) {
            if (data && data.hasOwnProperty(key)) return data[key];
            else return null;
        }
        return data;
    }
    static remove(field, type = "session") {
        if (type === "session") sessionStorage.removeItem(field);
        else if (type === "local") localStorage.removeItem(field);
    }
}

export default Storage;