
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { Dropdown, IconButton } from 'rsuite';

function Notification() {
    return <></>
    const renderIconButton = (props, ref) => {
        console.log(props);
        return (
            <button className="bg-white p-0" {...props} ref={ref}><FontAwesomeIcon size="lg" icon={faBell} className="mx-2" /></button>
        );
    };
      

    return <Dropdown renderToggle={renderIconButton} placement="bottomEnd">
            <Dropdown.Item panel style={{ padding: 10, width: 250 }} >
                <p>Itt lesznek majd az értesítések</p>
            </Dropdown.Item>
        </Dropdown>
}

export default Notification;