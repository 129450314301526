import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";



const module = "user";
const url = "/user";

const List = () => {
    const { t } = useTranslation();
    const title = t("Felhasználók");
  

    const fields = [
        {
            id: "name",
            label: t("Név"),
            searchable: true,
            searchlike: "full",
            searchid: "user.name",
        },
        {
            id: "email",
            label: t("E-mail"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "status",
            label: t("Státusz"),
            searchable: true,
            searchtype: "select",
            searchid: "user.status",
            data: [
                {value: "1", label: t("Aktív")},
                {value: "0", label: t("Inaktív")}
            ]
        },
        {
            id: "phone_number",
            label: t("Telefonszám"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },
        {
            id: "_functions_"
        }
    ];

    return (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
     />);
};
const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    
    const fields = [
        // {
        //     type: "card",
        //     label: t("Felhasználó adatok"),
        //     children: [
                {
                    id: "name",
                    type: "text",
                    label: t("Teljes név"),
                    required: true,
                    group: [
                        {
                            id: "username",
                            type: "text",
                            label: t("Felhasználónév"),
                            required: true
                        }
                    ]
                },
                {
                    id: "email",
                    type: "email",
                    label: t("E-mail"),
                    required: true,
                    group: [
                        {
                            id: "phone_number",
                            type: "text",
                            label: t("Telefonszám")
                        }
                    ]
                },
                {
                    id: "user_group_id",
                    type: "select",
                    label: t("Jogosultság"),
                    required: true,
                    load: process.env.REACT_APP_API_END_POINT + "/user_group/active",
                    loadSettings: {columns: {value: "id", label: "name"}}
                },
                {
                    id: "status",
                    type: "checkbox",
                    label: t("Aktív"),
                }
        //     ]
        // }
    ];
    if (!id) fields.push({
        type: "card",
        label: t("Jelszó beállítása"),
        children: [        
            {
                id: "password",
                type: "password",
                label: t("Jelszó"),
                required: true
            },
            {
                id: "repassword",
                type: "password",
                label: t("Jelszó újra"),
                required: true
            }     
        ]
    });

    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={() => cancelSplitScreen()} 
        fields={fields} 
        options={{afterSubmit: () => cancelSplitScreen()}}
    />);    
};

const exportedObject = { Edit, List };
export default exportedObject;