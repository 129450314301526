import { useState } from 'react';
import { useTranslation } from "react-i18next";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import { Timeline } from 'rsuite';
import Formgenerator from "../Form";
import { GetIp } from "../Device";


function Help() {
    const { t } = useTranslation();
    const [help, setHelp] = useState(false);

    const openHelp = () => {
        setHelp(!help)
    }
    const closeHelp = () => {
        setHelp(false)
    }

    const fields = [
        {
            id: "text",
            type: "textarea",
            label: "Üzenet",
            required: true,
            rows: 5
        },
        {
            id: "submit",
            type: "submit",
            label: "Küldés"
        }
    ];

    
    const ip = GetIp();
    const beforeSubmit = (data) => {
        data.url = window.location.href;
        data.browser = navigator.userAgent;
        data.ip = ip;
        data.screen = window.screen.width + 'x' + window.screen.height;
        data.language = navigator.language;
        data.referer = document.referrer;
        return data;
    }

    const afterSubmit = (response) => {
        if (response.success) {
            closeHelp();
        }
    }


    return (
        <>
            <FontAwesomeIcon size="lg" icon={faCircleQuestion} onClick={openHelp} className="text-secondary mx-2" role="button" />
            <Offcanvas show={help} placement='end' backdrop="static" scroll={false}>
                <Offcanvas.Header className='align-items-center border-bottom'>
                    <div className="d-flex align-items-center w-100">
                        <Offcanvas.Title className='flex-grow-1 display-xs-semibold'>{t('Információk')}</Offcanvas.Title>
                        <FontAwesomeIcon icon={faTimes} role="button" onClick={() => { closeHelp() }} />
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h5>{('Kapcsolat')}</h5>
                    <p>{('Ha bármilyen problémád van, kérlek jelezd felénk!')}</p>
                    <Formgenerator action={process.env.REACT_APP_API_END_POINT + `/helpme`} fields={fields} options={{beforeSubmit: beforeSubmit, afterSubmit: afterSubmit}} />                    
                    <h5 className='mt-5'>{('Ütemterv')}</h5>
                    <Timeline isItemActive={index => index === 2}>           
                        <Timeline.Item>Fejlesztés 1</Timeline.Item>                        
                        <Timeline.Item>Fejlesztés 2</Timeline.Item>                        
                        <Timeline.Item>Fejlesztés 3</Timeline.Item>                        
                        <Timeline.Item>Fejlesztés 4</Timeline.Item>                        
                        <Timeline.Item>Fejlesztés 5</Timeline.Item>                        
                    </Timeline>                    
                    <h5 className='mt-5'>{t('Verzió')}</h5>
                    <div>v{process.env.REACT_APP_VERSION}</div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default Help;