import { Navigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import Formgenerator from "../../components/Form";
import showToast from '../../components/Toast';
import { useAuth } from "../../provider/authProvider";
import { useTranslation } from "react-i18next";

function Login () {
    const { t } = useTranslation();
    const { setToken, setRemember } = useAuth();

    const fields = [
        {
            id: "username",
            type: "text",
            label: t("Felhasználónév"),
            required: true
        },
        {
            id: "password",
            type: "password",
            label: t("Jelszó"),
            required: true
        },
        {
            id: "remember",
            type: "checkbox",
            label: t("Emlékezz rám"),
        },
        {
            id: "submit",
            type: "submit",
            label: t("Bejelentkezés"),
            className: "w-100"
        }
    ];
    
    function submitHandler(event) {
        event.preventDefault();
        let props = event.target;
        props.elements.username.disabled = true;
        props.elements.password.disabled = true;
        props.elements.remember.disabled = true;
        props.elements.submit.disabled = true;
        let sendData = {
            username: props.elements.username.value,
            password: props.elements.password.value,
            remember: props.elements.remember.checked
        };
        axios.post(props.action, sendData)
            .then(function (response) {
                props.elements.username.disabled = false;
                props.elements.password.disabled = false;            
                props.elements.remember.disabled = false;            
                props.elements.submit.disabled = false;  
                if (!response.data.success) {
                    props.elements.username.focus();
                    return showToast.error(response.data);
                }
                else {
                    if (response.data.message) showToast.success(response.data.message);                                    
                    localStorage.setItem("remember", JSON.stringify(sendData.remember));
                    setRemember(sendData.remember);
                    setToken(response.data.response.token);                
                }
            })
        .catch(function (error) {
            showToast.error(error);
        }); 
    }

    return (    
        <section className={"login d-flex align-items-lg-center vh-100"}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4 col-xl-4 mx-lg-auto mt-5 mt-md-0 card">
                        <h1 className="text-center mb-5 fw-light fs-2">{t("Bejelentkezés")}</h1>
                        <Formgenerator module="auth" onSubmit={submitHandler} action={process.env.REACT_APP_API_END_POINT + `/auth/login`} fields={fields} />
                    </div>
                </div>
            </div>
        </section>
    );
}

function Logout () {
    const { t } = useTranslation();
    const { setToken } = useAuth();
    setToken();
    showToast.success(t("Sikeres kijelentkezés!"),true);    
    return <Navigate to="/" />;
}

function AfterLogin () {
    const [searchParams] = useSearchParams();
    let url = "/dashboard";
    return <Navigate to={searchParams.get("redirect") && searchParams.get("redirect") !== "/logout" ? searchParams.get("redirect"): url} />;
}

export { Login, Logout, AfterLogin };