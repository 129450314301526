import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";
// import { Table, Button } from "react-bootstrap";
// import Rightside from "../../components/Rightside";
import { setValue } from "../../components/Form/copyvalue";
import { useRef } from "react";
// import axios from "axios";
import { useState } from "react";
import { throwError } from "../../components/Error";
import showToast from '../../components/Toast'; 
import axios from "axios";
import { useEffect } from "react";


// More gombhoz
import { Whisper, Dropdown, Popover, IconButton } from 'rsuite';
import MoreIcon from '@rsuite/icons/legacy/More';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faFileInvoice, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const module = "offer";
const url = "/offer";

const List = () => {   
    const { t } = useTranslation();        
    const title = t("Ajánlatok");
    const title_add = t("Ajánlat létrehozása");

    const fields = [
        {
            id: "code",
            label: t("Azonosító"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "partner_id",
            label: t("Partner"),
            searchable: true,
            searchtype: "select",
            load: process.env.REACT_APP_API_END_POINT + "/partner/active",
            loadValue: "id",
            loadLabel: "name"
        },
        {
            id: "date",
            label: t("Árajánlat dátuma"),
            searchable: true,
            searchtype: "full",
            cell: (e) => {
                return e.date ? new Date(e.date).toISOString().slice(0, 10) : t("Nincs megadva");
            }
        },
        {
            id: "expire_date",
            label: t("Érvényesség"),
            searchable: true,
            searchtype: "full",
            cell: (e) => {
                return e.expire_date ? new Date(e.expire_date).toISOString().slice(0, 10) : t("Nincs megadva");
            }
        },
        {
            id: "full_price_net",
            label: t("Nettó összeg"),
            searchable: true,
            searchlike: "full",
            cell: (e) => {
                return e.full_price_net ? e.full_price_net + " " + e.currency : t("Nincs megadva");
            }
        },
        {
            id: "receipt_status",
            label: t("Státusz"),
            searchable: true,
            searchtype: "select",
            data: [
                {value: "draft", label: t("Piszkozat")},
                {value: "offer", label: t("Ajánlat")},
                // {value: "invoice", label: t("Számla")},
            ]
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },        
        {
            id: "_functions_",
            cell: (row) => {
                const renderMenu = ({ left, top, className }, ref) => {

                    function downloadInvoice() {
                        axios.patch(process.env.REACT_APP_API_END_POINT + "/offer/invoice/" + row.id).then((response) => {
                            throwError(response);                                            
                            if (response.data.success) {
                                window.open(process.env.REACT_APP_API_END_POINT + '/' + response.data.response.file, '_blank', 'noopener,noreferrer');                                            
                                showToast.success(t("Sikeres számla készítés"));                                                    
                                row._listGenerator.fetchData();                                                    
                            }
                            if (response.data.errcode && response.data.errcode === 2066) {
                                setTimeout(() => {
                                    downloadInvoice();
                                }, 2000);
                            }
                        }).catch((error) => {
                            throwError(error);
                        }); 
                    }

                    return (            
                    <Popover ref={ref} className={className} style={{ left, top }} full>
                        <Dropdown.Menu>
                            {row.receipt_status === "draft" && <Dropdown.Item onClick={(e) => {
                                const MySwal = withReactContent(Swal)
                                MySwal.fire({
                                    icon: "info",
                                    text: t("Lezárjuk és árajánlatot készítünk. Biztosan folytatja?"),
                                    showCloseButton: true,
                                    showCancelButton: true,
                                    confirmButtonText: t('Igen'),
                                    cancelButtonText: t('Mégsem'),
                                    confirmButtonColor: '#3085d6',
                                    showLoaderOnConfirm: true,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                        axios.patch(process.env.REACT_APP_API_END_POINT + "/offer/offer/" + row.id).then((response) => {
                                                throwError(response);                                            
                                                if (response.data.success) {
                                                    window.open(process.env.REACT_APP_API_END_POINT + '/' + response.data.response.file, '_blank', 'noopener,noreferrer');                                            
                                                    showToast.success(t("Sikeres árajánlat készítés"));                                                    
                                                    row._listGenerator.fetchData();                                                    
                                                }
                                            }).catch((error) => {
                                                throwError(error);
                                            }); 
                                        }
                                    })                       
                            }
                            }>{t("Árajánlat készítése")}</Dropdown.Item>}
                            {row.receipt_status !== "draft" &&
                                <Dropdown.Item onClick={(e) => {
                                    window.open(process.env.REACT_APP_API_END_POINT + '/' + row.offer_file, '_blank', 'noopener,noreferrer');
                                }
                                } icon={<FontAwesomeIcon icon={faFileDownload} />}>{t("Árajánlat letöltése")}</Dropdown.Item>
                            }
                            {row.receipt_status !== "invoice" && 
                                <Dropdown.Item onClick={(e) => {
                                    const MySwal = withReactContent(Swal)
                                    MySwal.fire({
                                        icon: "info",
                                        text: t("Átkonvertáljuk az ajánlatot számlává. Biztosan folytatja?"),
                                        showCloseButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: t('Igen'),
                                        cancelButtonText: t('Mégsem'),
                                        confirmButtonColor: '#3085d6',
                                        showLoaderOnConfirm: true,
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                downloadInvoice();
                                            }
                                        })    
                                }
                                }>{t("Számla készítése")}</Dropdown.Item>
                            }
                            {row.receipt_status === "invoice" && <>
                                <Dropdown.Item onClick={(e) => {
                                    row.invoice_file ? window.open(process.env.REACT_APP_API_END_POINT + '/' + row.invoice_file, '_blank', 'noopener,noreferrer') : downloadInvoice()
                                }
                            } icon={<FontAwesomeIcon icon={faFileDownload} />}>{t("Számla letöltése")}</Dropdown.Item>
                                </>
                            }
                        {row.receipt_status !== "invoice" && <Dropdown.Item onClick={(e) => row._listGenerator.deleteRow(row._listGenerator.action,row.id,e)} icon={<FontAwesomeIcon icon={faTrash} />}>{t("Törlés")}</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Popover>
                    );
                };       
                return  <Whisper placement="autoVerticalEnd" trigger="click" speaker={renderMenu}>
                <IconButton appearance="subtle" icon={<MoreIcon />} />
              </Whisper>
            }            
        }
    ];

    return (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        title_add={title_add} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
        _onRowClick={(data,event) => {
            if (data.receipt_status !== "draft") {
                if (event.target.classList.contains("rs-table-cell-content")) {
                    // alert('Az ajánlat már lezárásra került!');
                    showToast.error(t("Az ajánlat már lezárásra került!"));
                }
                return false;
            }          
        }}
     />);
};
const Edit = ({id = 0, cancelSplitScreen, setSplitScreen}) => {
    const { t } = useTranslation();
    const formRef = useRef();

    const fields = [
        {
            id: "tab-base",
            type: "tab",
            children: [
                {
                    label: t("Alapadatok"),
                    children: [
                        {
                            id: "partner_id",
                            type: "select",
                            label: t("Partner"),
                            required: true,
                            load: process.env.REACT_APP_API_END_POINT + "/partner/active",
                            loadValue: "id",
                            loadLabel: "name"
                        },
                        {
                            id: "date",
                            label: t("Árajánlat dátuma"),
                            // required: true,
                            type: "text",
                            readOnly: true,
                            defaultValue: new Date().toISOString().slice(0, 10),
                            group: [
                                {
                                    id: "expire_date",
                                    label: t("Érvényesség"),
                                    required: true,
                                    type: "date",
                                    value: !id && new Date(new Date().setDate(new Date().getDate() + 14))
                                },
                                {
                                    id: "currency",
                                    label: t("Deviza"),
                                    type: "select",
                                    required: true,
                                    load: process.env.REACT_APP_API_END_POINT + "/currency/active",
                                    loadValue: "currency",
                                    loadLabel: "currency",
                                    value: !id && {value: "HUF", label: "HUF"}
                                },
                                {
                                    id: "payment_method",
                                    label: t("Fizetési mód"),
                                    type: "select",
                                    required: true,
                                    load: process.env.REACT_APP_API_END_POINT + "/payment_method/active",
                                    loadValue: "id",
                                    loadLabel: "name"
                                }                              
                            ]
                        },               
                        {
                            id: "receipt_status",
                            label: t("Piszkozat"),
                            // required: true,
                            type: "hidden",
                            defaultValue: "draft"
                            // checked: true
                        },
                        {
                            id: "note",
                            label: t("Megjegyzés"),
                            type: "textarea"
                        }
                    ]
                },
                {
                    label: t("Tételek"),
                    children: [
                        {
                            id: "items",
                            type: "custom",
                            content: id === 0 ? t("A funkció használatához előbb mentsd el az űrlapot!") : <EditChildren id={id} />
                        }
                    ]
                }
            ]
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{afterSubmit: (data, event, id) => {
            if (id == 0) {
                setSplitScreen(data.response);
            }
            else {
                cancelSplitScreen();
            }
        }}}
        ref={formRef}
    />);
};

const EditChildren = ({id = 0}) => {
    // const [productValue, setProductValue] = useState(null);
    const [productValues, setProductValues] = useState([]);
    const [selectValues, setSelectValues] = useState({product_id: [], product_name: "", quantity: 0, unit: "", price_net: 0, price_gross: 0, vat: {value: "27%", label: "27%"}});
    const { t } = useTranslation();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_END_POINT + "/product/active").then((response) => {
            throwError(response);
            let values = response.data.response.data;
            // let values = [];
            // response.data.response.data.map((item) => {
                // values.push({value: item.id, label: item.name});
            // });
            setProductValues(values);
        }).catch((error) => {
            throwError(error);
        });
    }, []);

    return <Listgenerator edit={{
        fields: [
        {
            id: "offer_id",
            type: "hidden",
            value: id
        },
        {
            id: "unit",
            type: "hidden",
            value: selectValues.unit
        },         
        {
            id: "product_name",
            type: "hidden",
            value: selectValues.product_name
        },                        
        {
            id: "product_id",
            label: t("Termék"),
            type: "select",
            required: true,
            options: productValues.map((item) => {
                return {value: item.id, label: item.name};
            }),
            loaddata: productValues,
            // load: process.env.REACT_APP_API_END_POINT + "/product/active",
            // loadValue: "id",
            // loadLabel: "name",
            value: selectValues.product_id,
            _onload: (e) => {
                if (e.value) {
                    let currency = document.querySelector(`#currency input[name="currency"]`).value;
                    let vat = document.querySelector(`#vat input[name="vat"]`).value;
                    setValue("price_net", e.loaddata.find(x => x.id == e.value.value)['saleprice_price_'+currency+'_1']);
                    setValue("price_gross", e.loaddata.find(x => x.id == e.value.value)['saleprice_price_'+currency+'_1'] * (1 + vat.replace("%", "") / 100));
                    setValue("unit", e.loaddata.find(x => x.id == e.value.value).unit);
                }
                return e;
            },
            _onchange: (e) => {
                if (e.value) {
                    let currency = document.querySelector(`#currency input[name="currency"]`).value;
                    let vat = document.querySelector(`#vat input[name="vat"]`).value;
                    let values = [];
                    let wreck = e.loaddata.find(x => x.id == e.value.value).wreck;
                    if (!wreck || +wreck <= 0) wreck = 1;
                    wreck = +wreck;
                    for (let i = wreck; i <= wreck * 100; i += wreck) {
                        i = Math.round(i * 100) / 100;
                        values.push({value: i, label: i + " " + e.loaddata.find(x => x.id == e.value.value).unit});
                    }
                    setSelectValues({
                        product_id: {value: e.value.value, label: e.value.label}, 
                        product_name: e.value.label, 
                        quantity: values,
                        unit: e.loaddata.find(x => x.id == e.value.value).unit,
                        price_net: e.loaddata.find(x => x.id == e.value.value)['saleprice_price_'+currency+'_1'],
                        price_gross: e.loaddata.find(x => x.id == e.value.value)['saleprice_price_'+currency+'_1'] * (1 + vat.replace("%", "") / 100),
                        vat: {value: vat, label: vat}
                    });
                }
                return e;
            }
        }, 
        {
            id: "quantity",
            label: t("Mennyiség"),
            type: "select",
            // disabled: true,
            required: true,
            // disabled: true,
            options: selectValues.quantity
        },    
        {
            id: "price_net",
            label: t("Nettó egységár"),
            // disabled: true,
            type: "text",
            defaultValue: selectValues.price_net,
            _onchange: (e) => {
                if (!e.value) {
                    setValue("price_gross", 0);
                    return e;
                }                                        
                let vat = document.querySelector(`#vat input[name="vat"]`).value;
                setValue("price_gross", e.value * (1 + vat.replace("%", "") / 100));
                return e;
            }
        },
        {
            id: "vat",
            label: t("Áfa"),
            type: "select",
            load: process.env.REACT_APP_API_END_POINT + "/vat/active",
            loadValue: "name",
            loadLabel: "name",
            defaultValue: selectValues.vat,
            _onchange: (e) => {
                if (!e.value) {
                    setValue("price_gross", 0);
                    return e;
                }
                let price_net = document.querySelector(`#price_net`).value;
                setValue("price_gross", price_net * (1 + parseFloat(e.value.value.replace("%", "")) / 100));
                return e;
            }
        },                                
        {
            id: "price_gross",
            label: t("Bruttó egységár"),
            // disabled: true,
            type: "text",
            defaultValue: selectValues.price_gross,
        },
        {
            id: "note",
            label: t("Megjegyzés"),
            // disabled: true,
            type: "textarea",
        }                                
        ], 
            title: "Tételek", 
            action: process.env.REACT_APP_API_END_POINT + "/offer_item/" + id,
            className: 'offcanvas-notransition'
        }}
        module="offer_item"
        action={process.env.REACT_APP_API_END_POINT + "/offer_item/active/" + id + "/:id"} 
        deleteurl={process.env.REACT_APP_API_END_POINT + "/offer_item/" + id + "/:id"} 
        paginationServer 
        columnSearch 
        title="Tételek" 
        columns={
            [
                {
                    id: "product_name",
                    label: t("Termék"),
                    searchable: true,
                    searchlike: "full",
                    // searchtype: "select",
                    // load: process.env.REACT_APP_API_END_POINT + "/product/active",
                    // loadValue: "id",
                    // loadLabel: "name"
                },
                {
                    id: "quantity",
                    label: t("Mennyiség"),
                    searchable: true,
                    searchtype: "number",
                    cell: (e) => {
                        return e.quantity + " " + e.unit;
                    }
                },                                
                {
                    id: "price_net",
                    label: t("Nettó egységár"),
                    searchable: true,
                    searchlike: "full",
                },
                {
                    id: "price_gross",
                    label: t("Bruttó egységár"),
                    searchable: true,
                    searchlike: "full",
                },
                {
                    id: "full_price_net",
                    label: t("Nettó összeg"),
                    searchable: true,
                    searchlike: "full",
                },
                {
                    id: "full_price_gross",
                    label: t("Bruttó összeg"),
                    searchable: true,
                    searchlike: "full",
                },
                {
                    id: "note",
                    label: t("Megjegyzés"),
                    searchable: true,
                    searchlike: "full",
                    hide: true,
                    fullText: true
                },
                {
                    id: "_functions_"
                }
            ]
        }
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        />;
};
   
export default { Edit, List }