import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";

const module = "city";
const url = "/company/city";

const List = () => {   
    const { t } = useTranslation();        
    const title = t("Települések");

    const fields = [
        {
            id: "country",
            label: t("Ország"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "zip",
            label: t("IRSZ"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "city",
            label: t("Település"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "county",
            label: t("Vármegye"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },
        {
            id: "_functions_"
        }
    ];

    return (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
     />);
};
const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    
    const fields = [
        {
            id: "country",
            type: "text",
            label: t("Ország"),
            defaultValue: "Magyarország",
            required: true,
        },
        {
            id: "zip",
            type: "text",
            label: t("IRSZ"),
            required: true,
        },
        {
            id: "city",
            type: "text",
            label: t("Település"),
            required: true,
        },
        {
            id: "county",
            type: "text",
            label: t("Vármegye"),
            required: false,
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{afterSubmit: () => cancelSplitScreen()}}    
    />);
};

export default { Edit, List }