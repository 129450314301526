import { useState } from 'react';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faPlusCircle, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import menus from '../../menu.json'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Logo from './logo';
import { IsMobile } from '../Device';
import Permission from '../Permission';

library.add(fas);


const MainSidebar = ({...props}) => {
    const [toggled, setToggled] = useState(IsMobile());   
    
    // window.addEventListener('click', function(e) {
    //     console.log(e.target.classList);
        
    //     if (!e.target.classList.contains('sidebar-hamburger') && !e.target.classList.contains('ps-sidebar-container')) {
    //         document.querySelector('aside').classList.remove('ps-toggled');
    //         document.querySelector('sidebar-hamburger').addClass('fa-caret-right');
    //         document.querySelector('sidebar-hamburger').removeClass('fa-caret-left');
    //     }
    // });

    const RenderMenu = ({menu, ...props}) => {
        const [active, setActive] = useState(window.location.pathname);
        return menu.children ? 
                <SubMenu key={menu.name} label={menu.name} {...props}>
                    {menu.children.map((item, index) => {
                        return item.children 
                        ? 
                            <RenderMenu key={item.url + item.name} menu={item} defaultOpen /> 
                        : 
                            <Permission permission={item.permission} /> &&
                            <MenuItem 
                                key={item.url + item.name} 
                                component={ <Link to={item.url} /> } 
                                onClick={() => { setToggled(IsMobile()); setActive(item.url) }}
                                suffix={(item.suffix && <Permission permission={item.suffix.permission} />) && <Link to={item.suffix.url}>{item.suffix.name === "+" ? <FontAwesomeIcon icon={faPlusCircle} /> : item.suffix.name}</Link> }
                                icon={item.icon && <FontAwesomeIcon icon={item.icon} />}
                                active={active === item.url}
                            >{item.name}</MenuItem>
                    })}
                </SubMenu>
            : 
                <Permission permission={menu.permission} /> &&
                <MenuItem 
                    key={menu.url + menu.name} 
                    component={ <Link to={menu.url} /> } 
                    icon={menu.icon && <FontAwesomeIcon icon={menu.icon} />}
                    active={active === menu.url}
                >{menu.name}</MenuItem>
                          
    }

    return (
        <>
            <FontAwesomeIcon className={(!toggled ? "toggled " : "") + `sidebar-hamburger position-fixed`} role="button" style={{top: "calc(var(--header-top-height) / 3 - 3px)", zIndex: 11}} size="lg" icon={!toggled ? faCaretLeft : faCaretRight} onClick={() => setToggled(!toggled)}/>
            <Sidebar backgroundColor="#fff" toggled={!toggled} rootStyles={{position: "fixed", top: 0, height: "100vh", width: "var(--sidebar-left-width)", zIndex: 11}} {...props}>
                <Logo />                
                <Menu>
                    {/* {menus.leftmenu.filter(item => typeof item.url === "string" ? item.url === window.location.pathname : item.url.some(url => url === "/" + window.location.pathname.split('/')[1])).map((item, index) => { */}
                    {menus.leftmenu.map((item, index) => {
                        if (<Permission permission={item.permission} />) {
                            return <RenderMenu key={index + "Menu"} menu={item} defaultOpen />;
                        }
                    })}   
                </Menu>          
            </Sidebar>
        </>
    );
}

export default MainSidebar;