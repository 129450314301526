import { toast } from 'react-toastify';


function showToast () {
    return <></>
}

showToast.success = (message, clearAll) => {
    // if (clearAll) toast.dismiss();
    toast.success(message);
}
showToast.error = (error) => {
    console.error(error);
    toast.error(error.message || error);
}

export default showToast;