import { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRightFromBracket, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Dropdown from 'react-bootstrap/Dropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Formgenerator from "../Form";

import Avatar from '../Avatar';
import { useUserData } from '../../provider/userProvider.jsx';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone';


function User() {
    const [showProfile, setShowProfile] = useState(false);
    const openProfile = () => {  if (!showProfile) setShowProfile(true) }
    const closeProfile = () => { setShowProfile(false) }
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user } = useUserData();

    function logoutCheck() {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            icon: "question",
            text: t("Biztosan kijelentkezel?"),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: t('Igen'),
            cancelButtonText: t('Mégsem'),
            confirmButtonColor: '#d33',
            showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    return navigate('/logout');
                }
            })  
    }  

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant='blank' align='end' className='p-1 d-flex align-items-center'>
                    {user.name && <Avatar id={user.id} name={user.name} image={user.photo} />}
                </Dropdown.Toggle>
                <Dropdown.Menu align={{ xs: 'start' }}>
                    <Dropdown.ItemText key={0}><strong>{user.name}</strong></Dropdown.ItemText>
                    <Dropdown.Item key={1} onClick={openProfile}><FontAwesomeIcon icon={faUser} role="button" style={{marginRight: "0.5rem"}}/> {t('Profilom')}</Dropdown.Item>
                    <Dropdown.Item key={2} onClick={logoutCheck}><FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: "0.5rem"}} /> {t('Kijelentkezés')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Profile user={user} show={showProfile} close={closeProfile} />
        </>
    )
}

function Profile({user,show,close}) {
    const { t } = useTranslation();
    const editor = useRef(null);
    const { getMe } = useUserData();

    const RenderAvatar = () => {
        const [userImageData, setUserImageData] = useState({
            photo: user.photo,
            original_photo: user.original_photo,
            original_pixels: {
                x: user.original_pixels && user.original_pixels.x ? user.original_pixels.x + 0.10 : 0,
                y: user.original_pixels && user.original_pixels.y ? user.original_pixels.y + 0.16 : 0
            },
            scale: user.original_pixels && user.original_pixels.scale ? user.original_pixels.scale : 1.2
        });        
        const [uploading, setUploading] = useState(false);

        const handleScroll = (event) => {
            let newScale = userImageData.scale + (event.deltaY > 0 ? 0.1 : -0.1);
            if (newScale < 1) newScale = 1;
            setUserImageData({...userImageData, scale: newScale});             
        }

        const removePhoto = () => {
            setUserImageData({
                photo: null,
                original_photo: null,
                original_pixels: {},
                scale: 1.2
            });
        }

        const handlePositionChange = (position) => {
            setUserImageData({...userImageData, original_pixels: position});
        }
        
        return <div className="position-relative d-flex justify-content-start"><Dropzone noClick={userImageData.original_photo ? true : uploading} noDrag={!uploading} multiple={false} maxFiles={1} accept={{'image/png': ['.png'], 'image/jpeg': ['.jpg','.jpeg']}} onDrop={(files) => {
                setUploading(true);
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onloadend = function () {
                    setUserImageData({
                        photo: reader.result,
                        original_photo: reader.result,
                        original_pixels: {},
                        scale: 1.2
                    });       
                    setUploading(false);            
                }                
            }}>
            {({getRootProps, getInputProps}) => (
                <div
                  {...getRootProps({
                    className: 'avatar-dropzone d-inline-block position-relative',
                    onDrop: event => event.stopPropagation()
                  })}
                >
                  <input {...getInputProps()} />
                    {userImageData.original_photo ? 
                        <AvatarEditor
                            ref={editor}
                            image={userImageData.original_photo}
                            width={100}
                            height={100}
                            border={10}
                            color={[255, 255, 255, 0.6]}
                            borderRadius={100}
                            scale={userImageData.scale}
                            rotate={0}
                            position={{ x: userImageData.original_pixels.x ? userImageData.original_pixels.x : 0, y: userImageData.original_pixels.y ? userImageData.original_pixels.y : 0 }}
                            onPositionChange={handlePositionChange}
                            onWheel={handleScroll}
                        /> :
                        <Avatar id={user.id} name={user.name} size="lg" loader={uploading} role="button" />
                    }
                </div>
            )}
          </Dropzone>
          {userImageData.original_photo && <FontAwesomeIcon icon={faTrash} onClick={removePhoto} role="button" className="text-danger ps-2 pt-2" />}
          </div>     

    }
    

    const passwordRef = useRef(null);
    const fields = [
        {
            "type": "custom",
            "content": <RenderAvatar  />
        },
        {
            "id": "name",
            "type": "text",
            "label": t("Név"),
            // "placeholder": "Név",
            "value": user.name,
            "required": true
        },
        {
            "id": "username",
            "type": "text",
            "label": t("Felhasználónév"),
            "value": user.username,
            "required": true
        },
        {
            "id": "email",
            "type": "email",
            "label": t("E-mail"),
            // "placeholder": "E-mail",
            "value": user.email,
            "required": true
            
        },
        {
            "id": "phone_number",
            "type": "text",
            "label": t("Telefonszám"),
            // "placeholder": "Telefonszám",
            "value": user.phone
        },
        {
            "type": "divider"
        },
        {
            "id": "current_password",
            "type": "password",
            "label": t("Új jelszó beállítása"),
            "placeholder": t("Jelenlegi jelszó"),
            "required": false
        },
        {
            "id": "password",
            "type": "password",
            // "label": "Új jelszó beállítása",
            "placeholder": t("Új jelszó"),
            "required": false,
            "ref": passwordRef
        },
        {
            "id": "repassword",
            "type": "password",
            // "label": "Új jelszó újra",
            "placeholder": t("Új jelszó újra"),
            // "required": true,
            "onBlur": (event) => {
                if (event.target.value !== passwordRef.current.value) {
                    event.target.setCustomValidity(t('A két jelszó nem egyezik!'));
                } else {
                    event.target.setCustomValidity('');
                }
                event.target.reportValidity();
            }
        }
    ];

    const beforeSubmit = (data) => {
        if (editor.current) {
            data.photo = editor.current.getImage().toDataURL();
            data.original_photo = editor.current.props.image;
            data.original_pixels = {...editor.current.getCroppingRect(), scale: editor.current.props.scale};
        }
        return data;
    }
    const afterSubmit = (response) => {
        close();
        getMe();        
    }
   
    return (
        <Offcanvas id="profile" key="profile" show={show} placement='end' backdrop="static" scroll={false} autoFocus>
            <Offcanvas.Header className='align-items-center border-bottom'>
                <div className="d-flex align-items-center w-100">
                    <Offcanvas.Title className='flex-grow-1 display-xs-semibold'>{t('Profilom')}</Offcanvas.Title>
                    <FontAwesomeIcon icon={faTimes} role="button" onClick={() => { close() }} />
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formgenerator module="user" options={{beforeSubmit: beforeSubmit, afterSubmit: afterSubmit}} id={user.id} action={process.env.REACT_APP_API_END_POINT + `/user`} fields={fields} />
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default User;