import showToast from './../Toast';

function throwError(response) {
    if (response.data) {
        if (!response.data.success) {
            if (response.data.message) showToast.error(response.data.message);
            let error = new Error(response.data.message);
            error.code = response.data.errcode;
            window.componentDidCatch(error, error.code);
            if (response.data.callback) response.data.callback();
            // throw new Error(error);
        }
        else {
            if (response.data.message) showToast.success(response.data.message);
        }
    }
}

export { throwError };