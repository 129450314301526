import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
// import { setValue } from "../../components/Form/copyvalue";
import axios from "axios";
import Select from 'react-select';
import types from "./types.json";
// import payment_methods from "./payment_methods.json";
// import partner_address_types from "./partner_address_types.json";

const module = "product";
const url = "/product";

const List = () => {   
    const { t } = useTranslation();        
    const [storage, setStorage] = useState(null);
    const [storages, setStorages] = useState(null);
    const title = t("Cikktörzs");

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_END_POINT + "/storage/active?fields=id,name")
        .then((response) => {
            setStorage(response.data.response.data[0].id);
            setStorages(response.data.response.data);            
        });
    }, []);
    

    const fields = [
        {
            id: "type",
            label: t("Típus"),
            searchable: true,
            searchtype: "select",
            data: types.map((item) => {
                return {value: item.id, label: t(item.name)}
            })
        },
        {
            id: "name",
            label: t("Név"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "tonus",
            label: t("Tónus"),
            searchable: true,
            searchlike: "full",
            hide: true
        },
        {
            id: "caliber",
            label: t("Kaliber"),
            searchable: true,
            searchlike: "full",
            hide: true
        },
        {
            id: "stock",
            label: t("Készlet"),
            searchable: true,
            cell: (row) => {
                if (row.stock === undefined) row.stock = 0;
                return parseFloat(row.stock);
            }
        },
        {
            id: "occupied",
            label: t("Foglalt"),
            searchable: true,
            cell: (row) => {
                if (row.occupied === undefined) row.occupied = 0;
                return parseFloat(row.occupied);
            }
        },
        {
            id: "free",
            label: t("Szabad"),
            searchable: true,            
            cell: (row) => {
                if (row.free === undefined) row.free = 0;
                return parseFloat(row.free);
            }
        },
        {
            id: "other_stock",
            label: t("Egyéb készlet"),
            searchable: true,            
            cell: (row) => {
                if (row.other_stock === undefined) row.other_stock = 0;
                return parseFloat(row.other_stock);
            }
        },
        {
            id: "other_free",
            label: t("Egyéb szabad"),
            searchable: true,            
            cell: (row) => {
                if (row.other_free === undefined) row.other_free = 0;
                return parseFloat(row.other_free);
            }
        },
        {
            id: "status",
            label: t("Használatban"),
            searchable: true,
            searchtype: "select",
            data: [
                {value: 1, label: t("Igen")},
                {value: 0, label: t("Nem")}
            ]
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },        
        {
            id: "_functions_"
        }
    ];

    return storage === null ? <></> : (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all?storage=${storage}`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}},
            {storageSelector: {content: <span key="product-storage-selector" className="mt-3 mt-lg-0 ms-lg-auto text-nowrap z-3 w-75 w-lg-25 d-block d-md-flex align-items-center me-3">Válassz raktárat: 
            <Link className="mx-2" to={"/company/storage"}><FontAwesomeIcon icon={faExternalLinkAlt} /></Link>
            <Select 
                className="ms-0 ms-lg-2 mt-2 mt-lg-0 w-100"
                isLoading={storages ? false : true} 
                isSearchable={true} 
                isMulti={false}
                isClearable={false} 
                defaultValue={storage ? {value: storage, label: t(storages.find((item) => item.id === storage).name)} : null}
                options={
                    storages ? storages.map((item) => {
                        return {value: item.id, label: t(item.name)}
                    }) : []
                }
                onChange={(e) => setStorage(e.value)}
                placeholder={t("Válassz raktárat")}
            /></span>}},
        ]}
        SplitScreen={Edit}
     />);
};
const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    const [salePrices, setSalePrices] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_END_POINT + "/currency/active")
        .then((response) => {
            let currencies = response.data.response.data;
            axios.get(process.env.REACT_APP_API_END_POINT + "/pricegroup/active")
            .then((response) => {
                let prices = [];
                currencies.forEach((item) => {
                    let groups = [];
                    response.data.response.data.forEach((group) => {
                        groups.push({
                            id: "saleprice_price_" + item.currency + "_" + group.id,
                            name: "saleprice[price][" + item.currency + "]["+group.id+"]",
                            label: group.name,
                            type: "text",
                            append: item.currency,
                            // _onchange: (e) => {
                            //     let price = parseFloat(document.getElementById("last_purchase_gross_price").value);
                            //     setValue("saleprice_profit_" + item.currency + "", ((e.value - price) / price * 100).toFixed(2));
                            //     return e;                                
                            // },
                            group: [
                                {
                                    id: "saleprice_profit_" + item.currency + "_" + group.id,
                                    name: "saleprice[profit][" + item.currency + "]["+group.id+"]",
                                    label: t("Haszon"),
                                    type: "text",
                                    append: "%",                                    
                                    // _onchange: (e) => {
                                    //     let price = parseFloat(document.getElementById("last_purchase_gross_price").value);
                                    //     setValue("saleprice_price_" + item.currency + "", (price + price * e.value / 100));
                                    //     return e;                                
                                    // },
                                }
                            ]
                        });
                    });                      

                    prices.push({
                        label: item.note + " (" + item.currency + ")",
                        children: groups
                    });                
                });
                setSalePrices({
                    id: "tab-sales-prices",
                    type: "tab",
                    children: prices
                });
            });
        });
    }, []);

    if (salePrices === null) return <></>;
    const fields = [
        {
            id: "tab-base",
            type: "tab",
            children: [
                {
                    label: t("Adatok"),
                    children: [
                        {
                            id: "type",
                            label: t("Típus"),
                            required: true,
                            type: "select",
                            options: types.map((item) => {
                                return {value: item.id, label: t(item.name)}
                            })
                        },
                        {
                            id: "status",
                            label: t("Használatban"),
                            type: "checkbox",                                                   
                        },
                        {
                            id: "in_pricelist",
                            label: t("Árlistában"),
                            type: "checkbox",
                        },                        
                        {
                            id: "name",
                            type: "text",
                            label: t("Név"),
                            required: true,
                            group: [
                                {
                                    id: "tonus",
                                    label: t("Tónus"),
                                    type: "text",
                                },
                                {
                                    id: "caliber",
                                    label: t("Kaliber"),
                                    type: "text",
                                }
                            ]                               
                        },                        
                        {
                            id: "industry_id",
                            label: t("Üzletág"),
                            type: "select",
                            load: process.env.REACT_APP_API_END_POINT + "/industry/active",
                            loadValue: "id",
                            loadLabel: "name",
                            editable: true
                        },                    
                        {
                            id: "product_group_id",
                            label: t("Termékcsoport"),
                            type: "select",
                            load: process.env.REACT_APP_API_END_POINT + "/product_group/active",
                            loadValue: "id",
                            loadLabel: "name",
                            editable: true
                        },                
                        {
                            id: "product_type_id",
                            label: t("Termékfajta"),
                            type: "select",
                            load: process.env.REACT_APP_API_END_POINT + "/product_type/active",
                            loadValue: "id",
                            loadLabel: "name",
                            editable: true
                        },          
                        {
                            id: "unit_id",
                            label: t("Mennyiségi egység"),
                            type: "select",
                            load: process.env.REACT_APP_API_END_POINT + "/unit/active",
                            loadValue: "id",
                            loadLabel: "name",
                            editable: true,
                            group: [
                                {
                                    id: "wreck",
                                    label: t("Kiszerelés"),
                                    type: "text",
                                }
                            ]                              
                        },
                        {
                            id: "barcode",
                            label: t("Vonalkód"),
                            type: "text",
                            group: [
                                {
                                    id: "sku",
                                    label: t("Azonosító"),
                                    type: "text",
                                }
                            ]
                        },
                        {
                            id: "warranty",
                            label: t("Garancia"),
                            type: "text",
                            append: "hó",
                        },
                        {
                            id: "finished",
                            label: t("Késztermék"),
                            type: "checkbox",                        
                        },                   
                        {
                            id: "basic_material",
                            label: t("Alapanyag"),
                            type: "checkbox",
                        },                   
                    ]
                },
                {
                    label: t("Árképzés"),
                    children: [
                        {
                            id: "tab-pricing",
                            type: "tab",
                            children: [
                                {
                                    label: t("Eladási árak"),
                                    children: [salePrices]
                                },
                                {
                                    label: t("Beszerzési ár"),
                                    children: [     
                                        {
                                            id: "last_purchase_net_price",
                                            label: t("Utolsó nettó beszerzési ár"),
                                            type: "text",   
                                            // _onchange: (e) => {
                                            //     setValue("last_purchase_gross_price", (e.value * 1.27).toFixed(2));
                                            //     return e;                                
                                            // },                                                                                     
                                            group: [
                                                {
                                                    id: "last_purchase_gross_price",
                                                    label: t("Utolsó bruttó beszerzési ár"),
                                                    type: "text",                                                           
                                                    // _onchange: (e) => {
                                                    //     setValue("last_purchase_net_price", (e.value / 1.27).toFixed(2));
                                                    //     return e;                                
                                                    // },                                                                     
                                                }
                                            ]
                                        },
                                        {
                                            label: "Alapértelmezett beszállító",
                                            id: "supplier_id",
                                            type: "select",
                                            load: process.env.REACT_APP_API_END_POINT + "/partner/active?type=supplier",
                                            loadValue: "id",
                                            loadLabel: "name",
                                        },
                                        {
                                            label: "Átlagos beszállítási idő",
                                            id: "average_delivery_time",
                                            type: "text",
                                            append: "nap",
                                        },
                                        {
                                            label: "Beszerzési ár megjegyzés",
                                            id: "purchase_note",
                                            type: "textarea",
                                        }
                                    ]
                                }
                            ]
                        }                   
                    ]
                },
                {
                    label: t("Egyéb adatok"),
                    children: [
                        {
                            id: "size",
                            label: t("Méret"),
                            type: "text",
                        },
                        {
                            id: "color",
                            label: t("Szín"),
                            type: "text",                            
                        },
                        {
                            id: "net_weight",
                            type: "text",
                            label: t("Nettó tömeg"),
                            group: [
                                {
                                    id: "gross_weight",
                                    label: t("Bruttó tömeg"),
                                    type: "text",
                                }
                            ]                               
                        },                        
                        {
                            id: "weight_pallet",
                            type: "text",
                            label: t("Egy raklap súlya"),
                            group: [
                                {
                                    id: "weight_box",
                                    label: t("Egy doboz súlya"),
                                    type: "text",
                                }
                            ]                               
                        }                       
                    ]
                },
                {
                    label: t("Polcszámok"),
                    children: [
                        {
                            type: "custom",
                            content: id === 0 ? t("A funkció használatához előbb mentsd el az űrlapot!") : <Listgenerator edit={{fields: [
                                {
                                    id: "product_id",
                                    type: "hidden",
                                    value: id
                                },                        
                                {
                                    id: "number",
                                    label: t("Polcszám"),
                                    type: "text",
                                    required: true
                                },
                                {
                                    id: "storage_id",
                                    label: t("Raktár"),
                                    type: "select",
                                    load: process.env.REACT_APP_API_END_POINT + "/storage/active",
                                    loadValue: "id",
                                    loadLabel: "name",
                                },
                                {
                                    id: "capacity",
                                    label: t("Kapacitás"),
                                    type: "text",
                                }
                                ], title: "Polcszám", action: process.env.REACT_APP_API_END_POINT + "/product_shelf/" + id}}
                                module="product_shelf"
                                action={process.env.REACT_APP_API_END_POINT + "/product_shelf/active/" + id + "/:id"} 
                                deleteurl={process.env.REACT_APP_API_END_POINT + "/product_shelf/" + id + "/:id"} 
                                paginationServer 
                                columnSearch 
                                title="Polcszám" 
                                columns={
                                    [
                                        {
                                            id: "number",
                                            label: t("Polcszám"),
                                            searchable: true,
                                            searchlike: "full",
                                        },
                                        {
                                            id: "storage_id",
                                            label: t("Raktár"),
                                            searchable: true,
                                            searchlike: "select",
                                            load: process.env.REACT_APP_API_END_POINT + "/storage/active",
                                            loadValue: "id",
                                            loadLabel: "name",
                                        },
                                        {
                                            id: "capacity",
                                            label: t("Kapacitás"),
                                            searchable: true,
                                        },
                                        {
                                            id: "_functions_"
                                        }
                                    ]
                                }
                                buttons={[
                                    {add: {url: "", label: "+ Új létrehozása"}}
                                ]}
                                />
                        }
                    ]
                },
                {
                    label: t("Képek"),
                    children: [
                        {          
                            id: "images",
                            type: "file",
                            accept: "image/*",
                            multiple: true,

                        }
                    ]
                },
                {
                    label: t("Rendelések"),
                    children: [
                        {          
                            type: "custom",
                            content: "TODO: rendelések listája",
                        }
                    ]
                },
                {
                    label: t("Készletek"),
                    children: [
                        {          
                            type: "custom",
                            content: "TODO: készletek listája",
                        }
                    ]
                }
            ]
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{afterSubmit: () => cancelSplitScreen()}}
    />);
};

export default { Edit, List }