import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";

const module = "storage";
const url = "/company/storage";
const types = [
    {value: "external", label: "Külső"},
    {value: "internal", label: "Belső"},
    {value: "manufacturer", label: "Gyártó"}
];
const user_permissions = [
    {value: "full", label: "Teljes"},
    {value: "edition", label: "Kiadás"}
];

const List = () => {   
    const { t } = useTranslation();        
    const title = t("Raktárak");

    const fields = [
        {
            id: "type",
            label: t("Típus"),
            searchable: true,
            searchtype: "select",
            data: types
        },
        {
            id: "name",
            label: t("Megnevezés"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "priority",
            label: t("Prioritás"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "location_id",
            label: t("Telephely"),
            searchable: true,
            searchtype: "select",
            load: process.env.REACT_APP_API_END_POINT + "/location/all",
            loadValue: "id",
            loadLabel: "name"
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },
        {
            id: "_functions_"
        }
    ];

    return (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
     />);
};
const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    
    const fields = [
        {
            id: "tab-base",
            type: "tab",
            children: [
                {
                    label: t("Általános"),
                    children: [
                        {
                            id: "type",
                            type: "select",
                            label: t("Típus"),
                            required: true,
                            options: types
                        },
                        {
                            id: "name",
                            type: "text",
                            label: t("Megnevezés"),
                            required: true,
                        },
                        {
                            id: "priority",
                            type: "number",
                            label: t("Prioritás"),
                            defaultValue: 0
                        },
                        {
                            id: "location_id",
                            type: "select",
                            label: t("Település"),
                            required: false,
                            load: process.env.REACT_APP_API_END_POINT + "/location/all",
                            loadValue: "id",
                            loadLabel: "name"
                        },
                        {
                            id: "show_customer_on_receipt",
                            type: "checkbox",
                            label: t("Vevő megjelenítése kiadási/átadási bizonylaton"),
                        },
                        {
                            id: "show_supplier_on_receipt",
                            type: "checkbox",
                            label: t("Szállító megjelenítése kiadás/átadási bizonylaton"),
                        }
                    ]
                },
                {
                    label: t("Jogosultságok"),
                    children: [                    
                        {
                            type: "custom",
                            content: <Listgenerator edit={{fields: [
                                {
                                    id: "storage_id",
                                    type: "hidden",
                                    value: id
                                },
                                {
                                    id: "user_id",
                                    label: t("Név"),
                                    type: "select",
                                    load: process.env.REACT_APP_API_END_POINT + "/user/all",
                                    loadValue: "id",
                                    loadLabel: "name"
                                },
                                {
                                    id: "permission",
                                    label: t("Jogosultság"),
                                    type: "select",           
                                    options: user_permissions
                                }
                                ], title: "Jogosultságok", action: process.env.REACT_APP_API_END_POINT + "/storage_permission/:id"}}
                                module="storage_permission"
                                action={process.env.REACT_APP_API_END_POINT + "/storage_permission/active/:id"} 
                                deleteurl={process.env.REACT_APP_API_END_POINT + "/storage_permission/:id"} 
                                paginationServer 
                                columnSearch 
                                title="Jogosultságok" 
                                columns={
                                    [
                                        {
                                            id: "user_id",
                                            label: t("Név"),
                                            searchable: true,
                                            searchtype: "select",
                                            load: process.env.REACT_APP_API_END_POINT + "/user/all",
                                            loadValue: "id",
                                            loadLabel: "name",
                                            width: 400
                                        },
                                        {
                                            id: "permission",
                                            label: t("Jogosultság"),
                                            searchable: true,
                                            searchtype: "select",
                                            data: user_permissions,
                                            width: 200 
                                        },
                                        {
                                            id: "_functions_"
                                        }
                                    ]
                                }
                                buttons={[
                                    {add: {url: "", label: "+ Új létrehozása"}}
                                ]}
                                />
                        }
                    ]
                }
            ]
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{afterSubmit: () => cancelSplitScreen()}}    
    />);
};

export default { Edit, List }