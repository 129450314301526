import { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Table } from 'rsuite';
import { BarChart, PieChart, YAxis, Bars } from '@rsuite/charts';


function List() {
    const [showMonthyIncome, setShowMonthyIncome] = useState(false);
    const [showMonthyOutgo, setShowMonthyOutgo] = useState(false);
    const [showOrders, setShowOrders] = useState(false);

    let monthyIncomeData = [];
    for (let i = 0; i < 10; i++) {
        monthyIncomeData.push({ company: 'Minta cég név ' + i, price: Math.floor((Math.random() * (100000000 - 1000000 + 1)) + 1000000).toLocaleString() + ' Ft' });
    }
    let monthyOutgoData = [];
    for (let i = 0; i < 10; i++) {
        monthyOutgoData.push({ company: 'Minta cég név ' + i, price: Math.floor((Math.random() * (100000000 - 1000000 + 1)) + 1000000).toLocaleString() + ' Ft' });
    }
    let monthlyOrders = [];
    for (let i = 0; i < 10; i++) {
        monthlyOrders.push({ company: 'Minta cég név ' + i, price: Math.floor((Math.random() * (100000000 - 1000000 + 1)) + 1000000).toLocaleString() + ' Ft' });
    }



    let incomeData = [];
    for (let i = -30; i < 0; i++) {
        incomeData.push([
            new Date(new Date().setDate(new Date().getDate() + i)).toLocaleDateString('hu-HU', { month: 'short', day: 'numeric' }),
            Math.floor(Math.random() * 10),
        ]);
    }

    let orderData = [];
    for (let i = -30; i < 0; i++) {
        orderData.push([
            new Date(new Date().setDate(new Date().getDate() + i)).toLocaleDateString('hu-HU', { month: 'short', day: 'numeric' }),
            Math.ceil(Math.random() * (100 - 0 + 1)) + 0,
            Math.ceil(Math.random() * (100 - 0 + 1)) + 0,
        ]);
    }

    let mostOrderedData = [];
    mostOrderedData.push(['Tilezza', 30]);
    mostOrderedData.push(['GS Flooring', 30]);
    mostOrderedData.push(['KTL', 20]);
    mostOrderedData.push(['Zorka', 10]);
    mostOrderedData.push(['Egyéb', 10]);



    return (
        <div className="container-fluid">
            <Row className="align-items-center w-100">
                <Col className="d-lg-flex gap-2 align-items-center justify-content-between mb-4">
                    <h2 className='mb-3 mb-lg-0 text-uppercase fw-light fs-2 me-3'>Vezérlőpult</h2>        
                </Col>
            </Row>
            <Row className="align-items-center w-100 mb-4">
                <Col md="auto">
                    <div className="card p-0">
                        <div className="card-header">
                            <h5 className="card-title mb-0 p-2">Aktuális havi bevétel</h5>
                        </div>
                        <div className="card-body">
                            <h2 className="card-title">{Math.floor((Math.random() * (100000000 - 1000000 + 1)) + 1000000).toLocaleString()} Ft</h2>
                            <Modal show={showMonthyIncome} onHide={() => setShowMonthyIncome(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{textTransform: "uppercase"}}>Legnagyobb tartozók</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Table data={monthyIncomeData} height={300}>
                                        <Table.Column verticalAlign="middle" width={300}>
                                            <Table.HeaderCell>Cégnév</Table.HeaderCell>
                                            <Table.Cell dataKey="company"></Table.Cell>
                                        </Table.Column>
                                        <Table.Column verticalAlign="middle" width={120}>
                                            <Table.HeaderCell>Összeg</Table.HeaderCell>
                                            <Table.Cell dataKey="price"></Table.Cell>
                                        </Table.Column>
                                    </Table>
                                </Modal.Body>
                            </Modal>                            
                            <Button variant="primary" size="sm" className="float-end" onClick={() => setShowMonthyIncome(true)}>Részletek</Button>
                        </div>
                    </div>
                </Col>
                <Col md="auto">
                    <div className="card p-0">
                        <div className="card-header">
                            <h5 className="card-title mb-0 p-2">Aktuális havi kiadás</h5>
                        </div>
                        <div className="card-body">
                            <h2 className="card-title">{Math.floor((Math.random() * (100000000 - 1000000 + 1)) + 1000000).toLocaleString()} Ft</h2>
                            <Modal show={showMonthyOutgo} onHide={() => setShowMonthyOutgo(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{textTransform: "uppercase"}}>Legnagyobb tartozások</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Table data={monthyOutgoData} height={300}>
                                        <Table.Column verticalAlign="middle" width={300}>
                                            <Table.HeaderCell>Cégnév</Table.HeaderCell>
                                            <Table.Cell dataKey="company"></Table.Cell>
                                        </Table.Column>
                                        <Table.Column verticalAlign="middle" width={120}>
                                            <Table.HeaderCell>Összeg</Table.HeaderCell>
                                            <Table.Cell dataKey="price"></Table.Cell>
                                        </Table.Column>
                                    </Table>
                                </Modal.Body>
                            </Modal>                            
                            <Button variant="primary" size="sm" className="float-end" onClick={() => setShowMonthyOutgo(true)}>Részletek</Button>
                        </div>
                    </div>
                </Col>
                <Col md="auto">
                    <div className="card p-0">
                        <div className="card-header">
                            <h5 className="card-title mb-0 p-2">Rendelések átlagos értéke</h5>
                        </div>
                        <div className="card-body">
                            <h2 className="card-title">{Math.floor((Math.random() * (100000000 - 1000000 + 1)) + 1000000).toLocaleString()} Ft</h2>
                            <Modal show={showOrders} onHide={() => setShowOrders(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{textTransform: "uppercase"}}>Vásárlások listája</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Table data={monthlyOrders} height={300}>
                                        <Table.Column verticalAlign="middle" width={300}>
                                            <Table.HeaderCell>Cégnév</Table.HeaderCell>
                                            <Table.Cell dataKey="company"></Table.Cell>
                                        </Table.Column>
                                        <Table.Column verticalAlign="middle" width={120}>
                                            <Table.HeaderCell>Összeg</Table.HeaderCell>
                                            <Table.Cell dataKey="price"></Table.Cell>
                                        </Table.Column>
                                    </Table>
                                </Modal.Body>
                            </Modal>                            
                            <Button variant="primary" size="sm" className="float-end" onClick={() => setShowOrders(true)}>Részletek</Button>                            
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center w-100 mb-4">
                <Col>
                    <div className="card p-0">
                        <div className="card-header">
                            <h5 className="card-title mb-0 p-2">Elmúlt 30 nap bevételi statisztika</h5>
                        </div>
                        <div className="card-body">
                            <BarChart name="Elmúlt 30 nap bevételi statisztika" data={incomeData}>
                                <YAxis axisLabel={(value) => `${value} M Ft`} />
                            </BarChart>
                        </div>
                    </div>                
                </Col>
            </Row>
            <Row className="align-items-center w-100 mb-4">
                <Col>
                    <div className="card p-0">
                        <div className="card-header">
                            <h5 className="card-title mb-0 p-2">Elmúlt 30 nap rendelési statisztika</h5>
                        </div>
                        <div className="card-body">
                            <BarChart name="Elmúlt 30 nap rendelési statisztika" data={orderData}>
                                <YAxis axisLabel={(value) => `${value} db`} />
                                <Bars name="Online" />
                                <Bars name="Személyes" />
                            </BarChart>
                        </div>
                    </div>                     
                </Col>
            </Row>
            <Row className="align-items-center w-100 mb-4">
                <Col>
                    <div className="card p-0">
                        <div className="card-header">
                            <h5 className="card-title mb-0 p-2">Legkeresettebb termékek</h5>
                        </div>
                        <div className="card-body">
                            <PieChart name="Legkeresettebb termékek" data={mostOrderedData} label={({ name, value }) => `${name}: ${value}%`}>
                            </PieChart>
                        </div>
                    </div>                     
                </Col>
            </Row>
        </div>
    );
}

export default { List };