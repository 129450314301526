import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";

import { useQuery } from '@tanstack/react-query';
import { useParams } from "react-router-dom";
import axios from "axios";
import { throwError } from "../../components/Error";
import { Loader, Placeholder } from 'rsuite';

const module = "exchange";
const url = "/company/exchange";

const GetCurrency = () => {
    const { currencyId } = useParams();    
    const init = {data: {}, url: url + '/' + currencyId, status: "loading"};    
    const { status, data, error } = useQuery({
        queryKey: ["getCurrency", currencyId],
        queryFn: () => axios.get(process.env.REACT_APP_API_END_POINT + `/currency/${currencyId}`).then((res) => res.data),
        refetchOnWindowFocus: false
    });     
    if (error) return throwError(error);
    if (status === "success") return {data: data.response.data, url: url + '/' + currencyId, status: status};
    return {...init, status: status};
}

const Loading = () => (
    <div>
      <Placeholder.Paragraph rows={8} />
      <Loader backdrop vertical />
    </div>
  );
  

const List = () => {
    const { t } = useTranslation();        

    const currency = GetCurrency();
    if (currency.status !== "success") return <Loading />;
    const title = currency.status === "success" ? currency.data.currency + " " + t("árfolyam") : "";

    const fields = [
        {
            id: "date",
            label: t("Dátum"),
            searchable: true,
            searchlike: 'full',
            format: "yyyy-mm-dd"            
        },
        {
            id: "rate",
            label: t("Árfolyam"),
            searchable: true,
            searchlike: 'full'
        },
        {
            id: "_functions_"
        }
    ];

    return (<Listgenerator 
        module={module}
        url={currency.url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all/currency_id=${currency.data.id}`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {back: {url: "/company/currency", label: "Devizák", className: "btn btn-sm btn-secondary", icon: "fas fa-arrow-left"}},
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
     />);
};

const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    const currency = GetCurrency();

    const getRate = (e) => {
        e.target.disabled = true;
        e.target.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${t("MNB árfolyam")}`;

        const fields = ["rate"];
        fields.forEach((field) => {
            document.getElementById(field).disabled = true;
        });        
        axios.get(process.env.REACT_APP_API_END_POINT + `/exchange/rate/${currency.data.currency}`).then((response) => {
            throwError(response);
            if (response.data.success) {
                fields.forEach((field) => {
                    document.getElementById(field).disabled = false;
                    const input = document.getElementById(field);
                    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype,'value').set;
                    nativeInputValueSetter.call(input, response.data.response[field]);
                    const event = new Event('input', { bubbles: true });
                    input.dispatchEvent(event);                                       
                });                    
            }
            e.target.disabled = false;
            e.target.innerHTML = t("MNB árfolyam");
        });
    }

    const fields = [
        {
            id: "date",
            type: "date",
            label: t("Dátum"),
            required: true,
        },
        {
            id: "rate",
            type: "text",
            label: t("Árfolyam"),
            append: <button type='button' className='btn btn-primary' onClick={getRate}>{t("MNB árfolyam")}</button>,
            required: true
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{
            beforeSubmit: (data) => { data.currency_id = currency.data.id; return data;},
            afterSubmit: () => cancelSplitScreen()
        }}
    />);
};


export default { Edit, List }