import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useTranslation } from "react-i18next";

// More gombhoz
import { Whisper, Dropdown, Popover, IconButton } from 'rsuite';
import MoreIcon from '@rsuite/icons/legacy/More';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { useRef } from "react";

const module = "currency";
const url = "/company/currency";

const List = () => {   
    const whisperRef = useRef();
    const { t } = useTranslation();        
    const title = t("Devizanemek");
 

    const fields = [
        {
            id: "currency",
            label: t("Deviza"),
            searchable: true,
            searchlike: "full",
        },
        {
            id: "bankaccount_id",
            label: t("Számlaszám"),
            searchable: true,
            searchtype: "select",
            load: process.env.REACT_APP_API_END_POINT + "/bankaccount/active",
            loadValue: "id",
            loadLabel: "number"   
        },
        {
            id: "show_product",
            label: t("Cikktörzsben látható"),
            searchable: true,
            searchtype: "select",
            data: [
                {value: 1, label: t("Igen")},
                {value: 0, label: t("Nem")}
            ]
        },
        {
            id: "note",
            label: t("Megjegyzés"),
            searchable: true,
            fullText: true,
            hide: true
        },
        {
            id: "ts_add",
            label: t("Létrehozás ideje"),
            hide: true
        },
        {
            id: "usr_add",
            label: t("Létrehozó"),
            hide: true,
            format: "user"
        },
        {
            id: "ts_modify",
            label: t("Módosítás ideje"),
            hide: true
        },
        {
            id: "usr_modify",
            label: t("Utolsó módosító"),
            hide: true,
            format: "user"
        },
        {
            id: "_functions_",
            cell: (row) => {
                const renderMenu = ({ left, top, className }, ref) => {
                    return (            
                    <Popover ref={ref} className={className} style={{ left, top }} full>
                        <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/company/exchange/${row.id}`}>{t("Árfolyamok")}</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => row._listGenerator.deleteRow(row._listGenerator.action,row.id,e)} icon={<FontAwesomeIcon icon={faTrash} />}>{t("Törlés")}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Popover>
                    );
                };       
                return  <Whisper placement="autoVerticalEnd" trigger="click" speaker={renderMenu}>
                <IconButton appearance="subtle" icon={<MoreIcon />} />
              </Whisper>
            }            
        }
    ];

    return (<Listgenerator 
        module={module}
        url={url}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={[
            {add: {url: "", label: "+ Új létrehozása"}}
        ]}
        SplitScreen={Edit}
     />);
};

const Edit = ({id = 0, cancelSplitScreen}) => {
    const { t } = useTranslation();
    
    const fields = [
        {
            id: "currency",
            type: "select",
            label: t("Deviza"),
            load: process.env.REACT_APP_API_END_POINT + "/exchange/currencies",
            loadValue: "name",
            loadLabel: "name",
            required: true,
        },
        {
            id: "note",
            type: "textarea",
            label: t("Megjegyezés")
        },
        {
            id: "show_product",
            type: "checkbox",
            label: t("Cikktörzsben látható")
        },
        {
            id: "bankaccount_id",
            type: "select",
            label: t("Bankszámlaszám"),
            required: false,
            load: process.env.REACT_APP_API_END_POINT + "/bankaccount/active",
            loadValue: "id",
            loadLabel: "number",
        }
    ];
    
    return (<Formgenerator 
        id={id} 
        module={module} 
        action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        cancelurl={(e) => cancelSplitScreen(e)} 
        fields={fields} 
        options={{afterSubmit: () => cancelSplitScreen()}}
    />);
};


export default { Edit, List }