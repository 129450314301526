import { Outlet, Link } from 'react-router-dom';
import Help from './help';
import Notification from './notification.jsx';
import User from './user';
import MainSidebar from './mainsidebar.jsx';
import menus from './../../menu.json';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faBars } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { IsMobile } from '../Device';

library.add(fas);

export const Header = () => 
    <header className="position-fixed z-5 py-4" style={{top: 0, left: 0, width: "100%", height: window.innerWidth > 768 ? "var(--header-top-height)" : 60, backgroundColor: "white", boxShadow: "0 0 10px 0 rgba(0,0,0,.1)"}}>
        <div className='container-fluid px-4 py-0  h-100'>
            <div className='navbar navbar-expand-lg d-flex flex-nowrap justify-content-end gap-2 py-0 h-100'>
                {/* <TopMenu /> */}
                <Help />                
                <Notification />
                <User />
            </div>
        </div >    
    </header>

export const TopMenu = () => {
    const [open, setOpen] = useState(!IsMobile());

    window.addEventListener('click', function(e) {        
        if (window.innerWidth <= 768 && !e.target.classList.contains('navbar-toggler')) {
            setOpen(false);
        }
    });

    return <>
        <button className="navbar-toggler ms-auto" type="button" onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={faBars} style={{pointerEvents: "none"}} />
        </button>
        <Collapse in={open} className='navbar top-menu me-auto navbar-expand-lg'>
            <ul className='navbar-nav flex-row gap-0 gap-lg-2'>
                {menus.topmenu.map((item, index) =>
                    <li key={index} className={`nav-item ${item.url === window.location.pathname ? 'active' : ''}`} >
                        <Link className='nav-link text-uppercase' to={item.url}>
                        {item.icon && <FontAwesomeIcon icon={item.icon} className="me-2" />}
                        {item.name}</Link>
                    </li>
                )}
            </ul>
        </Collapse> 
    </>
}

export const Footer = () => <></>

export function MainContent() {
    return (
        <div className="admin">
            <div className='d-flex align-items-start align-self-stretch h-100 overflow-auto'>
                <MainSidebar />
                <main className="main-content d-flex flex-column justify-content-start align-items-start w-100" style={{marginTop: "var(--header-top-height)", height: "calc(100vh - var(--header-top-height))"}}>
                    <Header />
                    <Outlet />
                </main>        
            </div>
            <Footer />
        </div>
    );
}