import { Trans } from 'react-i18next';

const texts = {
    "required" : <Trans>Kötelező mező</Trans>,
    "noemail" : <Trans>Nem megfelelő e-mail cím</Trans>,
    "notax" : <Trans>Hibás adószám formátum</Trans>,
    "nobanknumber" : <Trans>Hibás számlaszám formátum</Trans>
};

const checkRequired = (data) => {
    if (Array.isArray(data.value)) {
        if (data.required && data.value.length === 0) {
            return texts.required;
        }
    }
    else if (data.required && !data.value) {
        return texts.required;
    }
};


const checkValidateEmail = (data) => {
    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };    
    if (data.type === "email" && data.value && !validateEmail(data.value)) {
        return texts.noemail;
    }
};

const checkValidate = (data) => {
    const validateEmail = (email) => { return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); };  
    if (data.type === "email" && data.value && !validateEmail(data.value)) return texts.noemail;

    const validateTax = (tax) => { return tax.match(/^[0-9]{8}-[1-5]-[0-9]{2}$/); };  
    if (data.validate && data.validate == "tax" && !validateTax(data.value)) return texts.notax;

    const validateBanknumber = (tax) => { return tax.match(/^[0-9]{8}-[0-9]{8}(-[0-9]{8})?$/); };  
    if (data.validate && data.validate == "banknumber" && !validateBanknumber(data.value)) return texts.nobanknumber;

};


function Validate(data) {
    let resp;
    resp = checkRequired(data); if (resp) return resp;
    resp = checkValidate(data); if (resp) return resp;
    return '';
}

export default Validate;