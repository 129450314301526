import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../provider/authProvider";
import { Uploader, Loader } from 'rsuite';
import AvatarIcon from '@rsuite/icons/legacy/Avatar';
import showToast from './../Toast';
import { PreviewApi } from '@zzwing/react-image'


const File = ({ field, ...props }) => {
    const { t } = useTranslation();
    const { token } = useAuth();
    const { formData, setField, checkSubmitDisabled, module, id, RenderField } = props;
    const [uploading, setUploading] = useState(false);    
    const [fileInfo, setFileInfo] = useState(null);     
    const UploaderRef = useRef(null);   

    // File érték kiolvasása
    function fileChangeHandler(response, file) {
        if (response.success) {
            if (response.response.id) {
                let newField = {...field}
                newField.children[0].value.push(response.response.id);
                newField.children[0].value = newField.children[0].value.filter((value) => value !== undefined);
                newField.children[0].filekey.push(file.fileKey);
                newField.children[0].filekey = newField.children[0].filekey.filter((key) => key !== undefined);
                setField(newField);
            }
        }
        checkSubmitDisabled(formData,false);
    }
    function fileChangeHandlerError(reason, file) {
        checkSubmitDisabled(formData,false);
        console.error(reason);
        showToast.error(reason);
    }
    function fileChangeHandlerStart(response, file) {
        checkSubmitDisabled(formData,true);
        if (!field.multiple && UploaderRef.current.root.children[0].children.length === 2) UploaderRef.current.root.children[0].children[0].children[1].click();
    }
    function fileChangeHandlerRemove(file) {
        let newField = {...field}
        newField.children[0].value.splice(newField.children[0].filekey.indexOf(file.fileKey), 1);
        newField.children[0].filekey = newField.children[0].filekey.filter((key) => key !== file.fileKey);
        setField(newField);
    }
    function fileShouldQueueHandler(file) {
        console.log(file);
        if (file[0].blobFile.size > process.env.REACT_APP_MAXFILESIZE) {
            showToast.error(t("A fájl mérete túl nagy!"));
            return false;
        }     
        return true;            
    }
    

    // Avatar feltöltés kezelése
    function previewFile(file, callback) {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }        
    function avatarChangeHandlerStart(response, file) {
        setUploading(true);
        previewFile(file.blobFile, value => {
            setFileInfo(value);
        });
    }
    function avatarChangeHandlerError(reason, file) {
        setFileInfo(null);
        setUploading(false);
        console.error(reason);
        showToast.error(reason);
    }
    function avatarChangeHandler(response, file) {
        if (response.success) {
            if (response.response.id) {
                let newField = {...field}
                newField.children[0].value = [response.response.data];
                newField.children[0].filekey = [file.fileKey];
                setField(newField);
            }
        }            
        setUploading(false);
    }


    if (field.listType === "avatar") {
        return <>
            <Uploader 
                fileListVisible={false} 
                defaultFileList={field.value && field.value} 
                ref={UploaderRef} 
                name={field.id} 
                accept="image/*" 
                listType="picture" 
                action={props.action.replace(":id", id)} 
                onUpload={avatarChangeHandlerStart} 
                onError={avatarChangeHandlerError} 
                onSuccess={avatarChangeHandler} 
                headers={{Authorization: `Bearer ${token}`}}
            >
                <button style={{ width: 80, height: 80 }}>
                    {uploading && <Loader backdrop center />}
                    {fileInfo ? (
                        <img src={fileInfo} width="100%" height="100%" alt="" />
                    ) : (
                        <AvatarIcon style={{ fontSize: 40 }} />
                    )}
                </button>                        
            </Uploader>
            {field.children && (field.children.map((child, id) => {
                return <RenderField key={field.id + "render-file" + id} {...child} />;
            }))}
        </>;
    }
    else {
        return <>
            <Uploader 
                defaultFileList={field.value && field.value} 
                ref={UploaderRef} 
                name={field.id.substr(0,field.id.length - 5)} 
                multiple={field.multiple && field.multiple} 
                accept={field.accept &&  field.accept} 
                listType={field.listType ? field.listType : (field.accept === "image/*" ? "picture" : "picture-text")} 
                action={process.env.REACT_APP_API_END_POINT + `/_uploader?module=` + module} 
                onUpload={fileChangeHandlerStart} 
                onError={fileChangeHandlerError} 
                onRemove={fileChangeHandlerRemove} 
                onSuccess={fileChangeHandler} 
                shouldQueueUpdate={fileShouldQueueHandler} 
                onPreview={(file) => {
                    if (file) {
                        PreviewApi.preview(file.url, [], false);
                        PreviewApi.show();
                    }
                }}
                headers={{Authorization: `Bearer ${token}`}} 
                draggable
            >
                <div style={{ width: '100%', height: 100, border: '1px solid #eaeaea', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span>{field.multiple ? t("Kattints ide vagy húzd rá a fájlokat") : t("Kattints ide vagy húzd rá a fájlt")}</span>
                </div>
            </Uploader>
            {field.children && (field.children.map((child, id) => {
                return <RenderField key={field.id + "render-file" + id} {...child} />;
            }))}
        </>;
    }
}

export default File;